@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

h1, h2, h3, h4, h5, h6, p, a, ul, li, hr, label {
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 48px;
	line-height: 56px;
	
	@media (max-width:667px) {
		font-size: 30px;
		line-height: 40px;
	}
}

h1.xl {
	font-size: 64px;
	line-height: 72px;

	@media (max-width:992px) {
		font-size: 48px;
		line-height: 46px;
	}
	
	@media (max-width:667px) {
		font-size: 36px;
		line-height: 46px;
	}
}

h2 {
	font-size: 40px;
	line-height: 48px;
	
	@media (max-width:667px) {
		font-size: 28px;
		line-height: 32px;
	}
}

h2.xl {
	font-size: 64px;
	line-height: 72px;
	
	@media (max-width:667px) {
		font-size: 56px;
    line-height: 66px;
	}
}

h2.md {
	font-size: 48px;
	line-height: 62px;
	
	@media (max-width:667px) {
		font-size: 36px;
		line-height: 46px;
	}
}


h3 {
	font-size: 32px;
	line-height: 46px;
	
	@media (max-width:667px) {
		font-size: 24px;
		line-height: 32px;
	}
}

h3.lg {
	font-size: 36px;
	line-height: 46px;
	
	@media (max-width:667px) {
		font-size: 24px;
		line-height: 32px;
	}
}

h4.lrg {
	font-size: 28px;
	line-height: 46px;
}

h4 {
	font-size: 24px;
	line-height: 32px;
}

h4.sm {
	font-size: 20px;
	line-height: 28px;
}

h5 {
	font-size: 18px;
	line-height: 32px;
}

p {
	font-size: 14px;
	line-height: 24px;

	@media (min-width:992px) {
		font-size: 16px;
	}
}

#benefits p {
	@media (max-width:992px) {
		font-size: 14px;
	}
}

p.sm {
	font-size: 11px;
	line-height: 11px;
}

.tagline {
	font-size: 12px!important;
}

.fz-24 {
	font-size: 24px;
}


.two-line-wrap {
	white-space: nowrap; /* Prevents wrapping onto multiple lines */
	overflow: hidden; /* Hides any content that overflows its container */
	display: -webkit-box; /* For compatibility with older versions of Safari */
	-webkit-line-clamp: 2; /* Limits the text to two lines */
	-webkit-box-orient: vertical; /* Sets the text to flow vertically */
  }

// Family

.font-inter {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
}

.font-helvetica {
	font-family: Helvetica, Sans-Serif;
	font-weight: 400;
}

// Font Weights

// Guide
// Light 300
// Regular 400
// Medium 500
// SemiBold 600
// Bold 700
// ExtraBold 800
// Black 900

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400!important;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.fw-800 {
	font-weight: 800;
}

.fw-900 {
	font-weight: 900;
}

// Text Colors

.text-primary{ color: $primary!important;}
.text-palebluegrey{	color: $palebluegrey;}
.text-paleblue{ color: $paleblue;}
.text-turquoise{ color: $turquoise;}
.text-blue1{ color: $blue1;}
.text-blue2{ color: $blue2;}
.text-blue3{ color: $blue3;}
.text-blue4{ color: $blue4;}
.text-navy{ color: $navy;}
.text-deepnavy{ color: $deepnavy;}
.text-bluegrey{ color: $bluegrey;}
.text-offbluegrey{ color: $offbluegrey;}
.text-yellow{ color: $yellow;}
.text-lightorange{ color: $lightorange;}
.text-deeporange{ color: $deeporange;}
.text-red{ color: $red;}
.text-paleorangered{ color: $paleorangered;}
.text-plum{ color: $plum;}
.text-bluenew{ color: $bluenew;}
.text-333 {
	color: #333;
}
.text-orange {
	color: #FFBC2D;
}

.text-79 {
	color: #797979;
}

.text-gradient {
	
	color: #16E7CF;
	background-image: -webkit-linear-gradient(270deg, #16E7CF 25%, #05A4CA 100%);
	background-clip: text;
	-webkit-background-clip: text;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
}

.text-gradient-90 {
	
	color: #16E7CF;
	background-image: -webkit-linear-gradient(180deg, #16E7CF 25%, #05A4CA 100%);
	background-clip: text;
	-webkit-background-clip: text;
	text-fill-color: transparent;
	-webkit-text-fill-color: transparent;
}

// Typograpghy Utils

.td-none {
	text-decoration: none;
}

.letters_spaced {
	letter-spacing: 6px;
}

.lh-70 {
	line-height: 70px !important;
}

.text_link_sm {
	text-decoration: none;
	color: white;
	font-size: 12px;
	
}