.about_hero {
	padding-top: 12rem;
	padding-bottom: 12rem;
	background: #F2F2F2;
	position: relative;
	
	.header_lines {
		bottom: -100px;
	}
}

.vimeo_wrapper_about {
	margin-top: -3rem;
}


.about_section {
	
	.scrolling_section {
		border-left: 2px solid #16E7CF;
		padding-top: 8rem;
	}
	
	.stickthis {
		padding-top: 8rem;
	}
	.item {
		min-height: 70vh;
		margin-left: 8rem;
		position: relative;
		
		&:before {
			content: "";
			width: 5rem;
			position: absolute;
			left: -8rem;
			top: 20px;
			height: 2px;
			background-color: #16E7CF;
			display: block;
		}
	}
}

.quick_links {
	.item {
		background: #004C7F;
		border-radius: 8px;
		padding: 3rem;
	}
}

.background_accent {
	background: #f2f2f2;
	position: absolute;
	height: 100%;
	width: 59%;
	display: block;
	z-index: -1;
	top: 0;
	right: 0;
}

// Why Pages

.why_hero {
	padding-top: 30vh;
	padding-bottom: 10vh;
	position: relative;
	
	img.header_lines {
		position: absolute;
		right: -5%;
		bottom: -60px;
		z-index: 9990;
		max-width: fit-content;
	}
}

.why_points {
	.wrapper {
		min-height: 100vh;
		padding-top: 20vh;
	}
}

.why_points_overlap {
	
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	margin-top: -25vh;
	
	.point_wrapper {
		background: #074269;
		border-radius: 8px;
		padding: 2rem;
		height: 100%;
	}
}

.point_wrapper {
	background: #074269;
	border-radius: 8px;
	padding: 2rem;
	height: 100%;
}

.points_background	{
	background: $primary;
	height: 100vh;
}

.casestudy_background	{
	background: #f2f2f2;
	height: 50vh;
}

.case_study {
	margin-top: -16vh;
	padding-bottom: 10rem;
	position: absolute;
	width: 100%;
}

.casestudy_form_wrapper {
	padding: 3rem;
	background: white;
	border-radius: 16px;
}

.reasons {
	padding-top: 10rem;
	padding-bottom: 10rem;
}

#about-us #scrollerWaypoint {
	margin-bottom: 0;
}

.line {
	background: -webkit-linear-gradient(180deg, #16E7CF 25%, #05A4CA 100%);
	height: 4px;
	width: 100%;
	position: relative;
	
	&:after {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #17e7d0;
		content: "";
		display: block;
		right: -20px;
		top: -8px;
		position: absolute;
	}
}

.warning_image {
	position: absolute;
    max-width: 100px;
	left: -100px;
    top: 17px;
}

.gdc_warning {
	margin-bottom: -60px;
}
