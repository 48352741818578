.mh-150 {
    min-height: 150px;
}
.card-grey {
    background: #F8F8F9;
    border-radius: 8px;
}

.card-stroke__lblue {
    border-radius: 8px;
    border: 2px solid rgba(44, 107, 174, 0.2);
}
.stats_block {
	display: inline-block;
    margin-right: 10px;

	p {
		margin-bottom: 0;
	}
}

.book_image {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}

.book_box {
	.row {
		min-height: 375px;
	}
}

.lh-70 {
	line-height: 70px !important;
}

.card-stroke__grey {
    border-radius: 8px;
    border: 2px solid #E1E4EC;
}

.card-stroke__lblue {
    border-radius: 8px;
    border: 2px solid rgba(44, 107, 174, 0.2);
}
.card-grey {
    background: #F8F8F9;
    border-radius: 8px;
}
.card_header {
    height: 120px;
}
.bg_grey {
    background: #F8F8F9;
}
.card_header {
    height: 120px;
}
.bg-primary {
    background-color: #131936 !important;
}
.equals_circle {
    width: 80px;
    height: 80px;
    background: white;
    border: 2px solid #D5E1EF;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: 20px;
    top: -40px;
}

.savings_card .row .SC_footer {
    background: #363B52;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 0 0 8px 8px;
}

.bg_blue_500 {
    background: #05A4CA;
}

.book_image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}
.book_box .row {
    min-height: 375px;
}
.br-8 {
	border-radius: 8px;
}

.savingshead {
	padding-top: 10rem;
}

.bg_blue_400 {
    background: #1C6EA6;
}