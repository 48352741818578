.careers_image {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	margin: auto;
	height: 90%;
}

.bd_gradient_ddb {
	background: linear-gradient(180deg, #004C7F 0%, #131936 100%);
}
.py-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.border_left_span {
	height: 19px;
	background: #16e7cf;
	width: 2px;
	display: inline-block;
	margin-left: -20px;
	margin-right: 20px;
	margin-bottom: -4px;
	
	@media (max-width: 667px) {
		display: none;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.careers_hero {
	padding-top: 16rem;
	padding-bottom: 10rem;
	margin-bottom: 5rem;
}

.hero_standard {
	padding-top: 16rem;
	padding-bottom: 10rem;
}

.careers_image_large {
	height: 100vh;
	
	@media (max-width: 992px) {
		height: auto;
		margin-bottom: 3rem;
	}
}

.c_block {
	.item_wrapper_careers {
		overflow: hidden;
		position: relative;
		border-radius: 8px;
	}
	
	.hover_show {
		background: white;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 999;
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0;
		transition: 0.3s;
		border-radius: 8;
	}
	
	&:hover {
		.hover_show {
			opacity: 1;
		}
	}
}

.item_wrapper_careers {
	h4.t {
		position: absolute;
		top: 30px;
		left: 30px;
		
		@media (max-width: 667px) {
			top: 20px;
			left: 20px;
		}
	}
}

.text-2col {
	-moz-column-count: 2;
	column-count: 2;
	-moz-column-gap: 20px;
	column-gap: 20px;
}

.job_description {
	p, ul, li {
		color: white;
		font-size: 14px;
		line-height: 24px;
	}
	
	ul {
		margin-left: 0;
		padding-left: 17px;
	}
	
	li {
		margin-left: 0!important;
	}
}

.applyform {
	border-radius: 8px;
}

.textformapply {
	font-size: 14px;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.legal_page h4 {
    color: #131936;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 14px;
    margin-top: 24px;
}

.referal {
	margin-top: 15rem;
}

.password_wrapper .input-group-addon a {
    font-size: 14px;
    font-weight: bold;
    color: #004C7F;
}

.password_wrapper .input-group-addon {
    position: absolute;
    top: 24px;
    right: 0px;
    display: block;
    width: 64px;
    height: 62px;
    text-align: right;
    padding-right: 16px;
    padding-top: 17px;
}

.password_wrapper {
    position: relative;
}

.text-link--secondary:hover,
.text-link--white:hover {
	cursor: pointer;
}

.modal .close {
	border: none;
	background: transparent;
    position: absolute;
    right: 20px;
    top: 20px;
}

.team_modal {
	.modal-dialog {
		max-width: 700px;
	}

	.modal-content {
		padding: 20px;
	}
}

.modal {
    z-index: 9999999;
}

.modal-backdrop.show {
    z-index: 999999;
}

div#costOfAuditModal {
    z-index: 9999999!important;

	h4 {
		font-size: 20px;
		line-height: 32px;
		font-weight: bold;
		margin-bottom: 0;
	}
}
.webinar_content {
	ul, li, ol {
		padding-left: 0;
		margin-left: 0;
	}
}