// Prefix = tp_

.tp_header {

    .main_wrapper {
        min-height: 80vh;
        height: 100%;
    }

    @media (max-width: 992px) {
        background: none!important;
    }
}

section.tp_header {
    margin-top: 7rem;
}

.tp_whatis {
    padding-bottom: 9rem;
    padding-top: 5rem;
}

.tp_whywork {
    padding-bottom: 4rem;
    padding-top: 2rem;
}
.tp_sect_2 {
    padding-top: 6rem;

    .tp_box {
        padding: 6rem 4rem;
        border-radius: 32px 32px 0 0;
        background: #4A516B;
        padding-bottom: 8rem;

        @media (max-width: 992px) {
            padding: 3rem;
        }
    }

    img.lines {
        position: absolute;
        bottom: 5%;
        right: -5%;

        @media (max-width: 992px) {
            display: none;
        }
    }
}

.tp_section_cindy {
    padding-top: 15rem;
    margin-top: -12rem;
    padding-bottom: 4rem;

    .divider {
        display: inline-block;
        width: 40px;
        height: 2px;
        background: #05A4CA;
        margin-bottom: 13px;
    }
}

.banner {

    margin-top: -9rem;

    .banner_wrapper {
        border-radius: 18px;
        padding: 4rem;
    }
}

.tp_items {
    margin-bottom: 5rem;
    padding-right: 3rem;

    @media (max-width: 992px) {
        text-align: center;

        margin-bottom: 3rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }
}
#top-employer {

    @media (max-width: 992px) {
        .py-10 {
            padding-bottom: 6rem;
            padding-top: 6rem;
        }
    }

}