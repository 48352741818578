.webinar_hero {
	padding-top: 15rem;
	padding-bottom: 10rem;
}

.webinar_content {
	padding-top: 6rem;
	padding-bottom: 10rem;
	position: relative;

	li {
		font-size: 14px;
	line-height: 24px;
	}

	.webinar_lines {
		position: absolute;
		left: 0;
		bottom: -20px;
	}
}

#clockdiv {
	.time_item {

		&:first-of-type {
			border-left: none;
		}

		border-left: 1px solid #16E7CF;
		padding-right: 30px;
		padding-left: 30px;
		span {
			font-size: 46px;
			font-weight: 700;
			color: white;
		}

		.smalltext {
			color: white;
			font-weight: 400;
			font-size: 14px;
		}
	}
}

.register {
	background: #F6F6F6;
	padding-bottom: 3rem;
	padding-top: 3rem;

	@media (max-width: 992px) {
		h2 {
			text-align: center;
		}
	}
}

.webinar_form_wrapper {
	padding: 3rem;
	background: white;
	border-radius: 16px;
	margin-top: -5rem;

	@media (max-width: 992px) {
		margin-top: 20px;
	}
}

.web_form_target {
	position: absolute;

	@media (max-width:667px) {
		position: relative;
	}
}