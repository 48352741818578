.text_link {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.btn_primary {
	font-weight: 700;
	font-size: 14px;
	border-radius: 8px;
	padding: 16px 26px;
	display: inline-block;
	text-decoration: none;
	transition: 0.4s;
	border: none;

	@media (max-width: 992px) {
		text-align: center;
		width: fit-content;
        margin: auto;
	}
	
	svg {
		max-width: 16px;
		margin-left: 10px;
		transition: 0.2s;
	}

	&:hover {
		text-decoration: none;
		svg {
			margin-left: 16px;
		}
	}
	
}

.btn_small {
	font-weight: 700;
	font-size: 14px;
    border-radius: 6px;
    padding: 10px 22px;
	display: inline-block;
	text-decoration: none;
	transition: 0.4s;
	
	svg {
		max-width: 16px;
		margin-left: 10px;
		transition: 0.4s;
	}

	&:hover {
		text-decoration: none;
		svg {
			margin-left: 16px;
		}
	}
}

.btn_md {
	font-weight: 700;
	font-size: 14px;
    border-radius: 6px;
    padding: 10px 22px;
	display: inline-block!important;
	text-decoration: none;
	transition: 0.4s;
	
	svg {
		max-width: 16px;
		margin-left: 10px;
		transition: 0.4s;
	}

	&:hover {
		text-decoration: none;
		svg {
			margin-left: 16px;
		}
	}
	
}

.btn_blue {
	background: $blue3;
	color: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}

.btn_bluenew {
	background: $bluenew;
	color: white;
	width: auto;

	&:hover {
		background: $blue3;
		color: white;
	}
}

.btn_orange {
	background: #F28F16;
	color: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}


.btn_navy {
	background: #131936;
	color: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}

.btn_brightblue {
	background: #3F8DD7;
	color: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}

.btn_fadedblue {
	background: #1F2540;
	color: white;

	&:hover {
		background: #191E37;
		color: white;
	}
}

.btn_grey {
	background: #D6D5D5;
	color: #131936;

	&:hover {
		background: #555555;
		color: white;
	}
}
.btn_turquoise {
	background: $turquoise;
	color: #131936;

	&:hover {
		background: #D6D5D5;
		color: #131936;
	}
}

.btn_darkblue {
	background: $primary;
	color: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}

.btn_white {
	color: $primary;
	background: white;

	&:hover {
		background: $blue2;
		color: white;
	}
}

.btn_turquoise_stroke {
	border: 2px solid $turquoise;
	color: white;
}

.hover_white:hover {
	color: white;
}

.btn_blue_stroke {
	border: 2px solid $blue3;
	color: $primary;
}


.btn_bluegrey_stroke {
	border: 2px solid #E1E4EC;
	color: #4A516B;
	transition: 0.3s;
}

.btn_bluegrey_stroke:hover {
	background: #E1E4EC;
	color: #4A516B;
}
