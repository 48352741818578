.hp_wrapper {

	position: relative;
	margin-bottom: -22rem;

	.hiring_slider {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		margin-top: 40px;
		z-index: 999;
		padding: 20px;
		box-shadow: 0px 0px 23px rgb(0 0 0 / 30%);
		background: white;
		border-radius: 8px;
		padding-bottom: 2rem;
	}
	
	img {
		max-width: 16rem;
	}
	
	
	.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, 
	.swiper-pagination-custom, .swiper-pagination-fraction {
		bottom: 0px;
		left: 0;
		width: 100%;
		position: relative;
	}
}

.vacancy {
	a {
		font-size: 20px;
		text-decoration: none;
	}
}

.vacanciyheader {
	margin-bottom: 4rem;
	padding-top: 18rem;
}

.job_vacancies {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.job_vacancies:nth-child(even) {
	background: #f2f2f2;
}