.practice_area_hero {
	height: 80vh;
	position: relative;
	
	.pa_header {
		position: absolute;
		right: -75px;
		bottom: -2px;
		height: 100%;
	}
}

.pa_wrapper {
	text-decoration: none;
	height: 100%;
	
	.btn_primary {
		opacity: 0;
		transition: 0.4s;
	}
	
	svg {
		width: 36px;
		height: 36px;
		transition: 0.4s;
	}
	
	h4, p {
		transition: 0.4s;
	}
	
	&:hover {
		.pa_item {
			background: #004C7F!important;
			h4, p {
				color: white!important;
			}
		}
		
		svg {
			color: white;
		}
		
		.btn_primary {
			opacity: 1;
		}
	}
}

.pa_item {
	padding: 4rem;
	padding-top: 6rem;
	transition: 0.4s;
	height: 100%;
}

.pa_blocks_wrapper {
	.row [class*="col-"]{
		margin-bottom: -99999px;
		padding-bottom: 99999px;
	}
	
	.row{
		overflow: hidden; 
	}
}

.pa_contact {
	background: #F6F6F6;
	
	.contact_form_wrapper {
		background: white;
		border-radius: 16px;
		padding: 4rem;
		z-index: 99;
		position: relative;
	}
}

// Practive Area - Single

.practice_areas_single_hero {
	position: relative;
	padding-top: 12rem;
	padding-bottom: 12rem;
	
	@media (max-width:992px) {
		background-position: center!important;
		
		height: auto;
		padding-top: 10rem;
		padding-bottom: 6rem;
	}
}

.line_float_pa {
	display: block;
	position: absolute;
	right: -5%;
	bottom: -30%;
	transition: 2s;
	max-width: 100%;
	transform: rotate(-15deg);
}

.growEase_pa {
	right: -100%;
	bottom: 80%;
}

#points {	
	.pa_item {
		background: #074269;
		border-radius: 8px;
		padding: 60px 20px;
	}
}

section#survive {
	padding-bottom: 24rem;
	margin-bottom: -18rem;
}

.pa_contact_single {
	margin-top: -5rem;
	padding-top: 7rem;
	background: white;
}

.pa_blocks_wrapper {
	
	// background-color: #F6F6F6;
	// padding-top: 16rem;
	// margin-top: -13rem;
	
	.pa_item {
		padding: 3rem;
		padding-top: 3rem;
		transition: 0.4s;
		height: 100%;
	}
	
	.btn_primary {
		font-weight: 700;
		font-size: 12px;
		border-radius: 8px;
		padding: 8px 14px;
		display: inline-block;
		text-decoration: none;
		transition: 0.4s;
		border: none;
		
		svg {
			max-width: 16px;
			margin-left: 10px;
			transition: 0.2s;
		}
		
		&:hover {
			text-decoration: none;
			svg {
				margin-left: 16px;
			}
		}
		
	}
	
	.swiper-slide {
		height: auto!important;
	}
	
	.swiper-slide:nth-child(even) {
		background: $palebluegrey;
	}
}

.pa_blocks_wrapper_single {
	
	background-color: #F6F6F6;
    padding-top: 18rem;
    margin-top: -19rem;
	
	@media (max-width:992px) {
		.line_float_pa {
			display: none;
		}
		
	}
	
	.pa_item {
		padding: 3rem;
		padding-top: 3rem;
		transition: 0.4s;
		height: 100%;
	}
	
	.btn_primary {
		font-weight: 700;
		font-size: 12px;
		border-radius: 8px;
		padding: 8px 14px;
		display: inline-block;
		text-decoration: none;
		transition: 0.4s;
		border: none;
		
		svg {
			max-width: 16px;
			margin-left: 10px;
			transition: 0.2s;
		}
		
		&:hover {
			text-decoration: none;
			svg {
				margin-left: 16px;
			}
		}
		
	}
	
	.swiper-slide {
		height: auto!important;
		background: white;
	}
	
	.swiper-slide:nth-child(2n+1) {
		background: $palebluegrey;
	}
}

.ebp_hero_item {
	border-radius: 8px;
	background: #074269;
	padding: 30px;
	
	p {
		color: white;
	}
}

.ebp_hero {
	padding-bottom: 10rem;
	
	@media (max-width:768px) {
		padding-bottom: 12rem;
	}
}

.ebp_video_wrapper {
	border-radius: 22px;
	background: linear-gradient(264deg, #2B6AAD 3.51%, #16E7CF 89.45%);
	margin-top: -15%;
	z-index: 9999;
	position: relative;
	@media (max-width:768px) {
		margin-top: -17%;
	}
}

.icon_block_gradient {
	background: linear-gradient(264deg, #16E7CF 3.51%, #2B6AAD 89.45%);
	border-radius: 12px;
	width: 60px;
	height: 60px;
	text-align:center;
	font-size: 28px;
}

.pg_section {
	position: relative;
	min-height: 80vh;
	
	.z-99 {
		position: relative;
		z-index: 99;
	}
}

.pg_points_wrapper {
	border-radius: 15px;
	border: 1px solid white;
	padding: 20px;
	margin-top: 20px;
	position: relative;
	
	.point_item {
		border-radius: 15px;
		background: linear-gradient(335deg, #16E7CF -49.49%, #2B6AAD 76.41%), #E1E4EC;
		backdrop-filter: blur(8.5px);
		padding: 24px;
		position: relative;
		overflow: hidden;
		height: 100%;
		
		.number {
			font-size: 120px;
			opacity: 0.4;
			position: absolute;
			left: -40px;
			font-weight: 800;
			color: white;
			top: 50%;
			line-height: 0;
		}
	}
}

.fade_wrapper {
	background: rgb(19,25,54);
	background: linear-gradient(0deg, rgba(19,25,54,1) 15%, rgba(19,25,54,0.48363095238095233) 100%);
	height: 100%;
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 9;
}

.ebp_stat {
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(8.5px);
	padding: 20px;
	height: 100%;
}

.cbiz_testimonial {
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(8.5px);
	text-align: center;
	overflow: hidden;

	.content_wrapper {
		padding: 20px;
	}

	.ti {
		font-size: 36px;
	}

	.logo_wrapper {
		background: white;
		width: 100%;
		padding-top: 16px;
		padding-bottom: 16px;
		text-align: center;
	}
}