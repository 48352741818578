.applicants_hero {
	padding-top: 13rem;
    padding-bottom: 6rem;
}

.applicant_signin {
	display: block;
	background: white;
	border-radius: 8px;
	padding: 40px;
	max-width: 540px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 998;
	box-shadow: 0px 19px 34px rgba(0, 0, 0, 0.15);

	
	.inputgroup {
		.form-control {
			height: 53px;
		}
		.btn_submit {
			background: #3279c6;
			border-radius: 8px;
			color: white;
			font-size: 15px;
			transition: 0.3s;
			border: none;
			padding: 12px 40px;
			height: 53px;
		}
	}

	.linkedin_btn {
		background: #2b6aad;
		text-decoration: none;
		display: block;
		padding: 16px;
		text-align: center;
		border-radius: 8px;
		color: white;
		font-size: 18px;
		font-weight: bold;
		transition: 0.3s;
		&:hover {
			text-decoration: none;
			background: #205895;
		}

		img {
			margin-right: 16px;
		}
	}

	.applicant_btn {
		background: #3279C6;
		text-decoration: none;
		display: block;
		padding: 16px;
		text-align: center;
		border-radius: 8px;
		color: white;
		font-size: 18px;
		font-weight: bold;
		transition: 0.3s;
		&:hover {
			text-decoration: none;
			background: #205895;
		}

		img {
			margin-right: 16px;
		}
	}

	.office_btn {
		background: #dc3e15;
		text-decoration: none;
		display: block;
		padding: 16px;
		text-align: center;
		border-radius: 8px;
		color: white;
		font-size: 18px;
		font-weight: bold;
		transition: 0.3s;

		&:hover {
			text-decoration: none;
			background: #d43c14;
		}

		img {
			margin-right: 16px;
		}
	}
}

.applicants_points {
	padding-top: 10rem;
	margin-top: 5rem;
	padding-bottom: 16rem;

	@media (max-width: 992px) {
		padding-top: 5rem;
		padding-bottom: 11rem;
	}
}

.app_points {
	margin-top: -8rem;

	.col-12.col-md-6.col-lg-4 {
		margin-bottom: 30px;
	}
	
	.hs_item {
		background: #074269;
		border-radius: 8px;
		padding: 30px;
		height: 100%;

		img {
			margin-bottom: 30px;
			max-height: 100px;
		}

		h4 {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
}

.background_underlay {
	background: #f2f2f2;
	height: 100%;
	display: block;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.app_point_slider {
	padding-top: 8rem;
	background: #F2F2F2;
	@media (max-width: 992px) {
		padding-top: 6rem;
	}
}

.hp_wrapper_application {
	padding: 20px;
	box-shadow: 0px 0px 23px rgb(0 0 0 / 30%);
	background: white;
	border-radius: 8px;
	position: relative;
    z-index: 999;

	@media (max-width: 667px) {
		position: relative;

		.swiper-pagination-bullet {
			width: 30px;
		}

		img {
			max-width: 150px;
			margin-bottom: 40px;
		}
	}

	// .swiper-pagination {
	// 	position: unset;
	// }

	.swiper {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 3rem 0rem;
		z-index: 1;
	}
}

.app_points_sub {
	margin-top: 7rem;
	@media (max-width: 992px) {
		margin-top: 3rem;
	}
}

.applicants_video {
	padding-top: 15rem;
	margin-top: -5rem;

	@media (max-width: 992px) {
		padding-top: 8rem;
	}

	img.lines_left {
		position: absolute;
		left: 0;
		top: 10%;
	}

	.video_slider {
		margin-bottom: -10rem;
	}

	.swiper-pagination.video_pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
		top: 11rem;
	}

	.vimeo_wrapper {
		margin-top: 0;
	}
}

.payforward {
	padding-top: 15rem;
	padding-bottom: 10rem;
}
.home_ca_section {
	padding-top: 8rem;
	padding-bottom: 8rem;
}

.home_ca_image {
	position: absolute;
	margin-top: -12rem;
}

.hp_wrapper_application .swiper-pagination {
    position: absolute;
    z-index: 999999;
    bottom: 10px;
}

// Onboarding

.onboarding_slider {
    padding-top: 25rem;
    background: #F2F2F2;
	@media (max-width: 992px) {
		padding-top: 6rem;
	}
}

.itinerary_slider {
    padding-top: 5rem;
    background: #F2F2F2;
}


.applicants_onboarding_video {
	padding-top: 8rem;
    margin-top: -5rem;

	@media (max-width: 992px) {
		padding-top: 8rem;
	}

	img.lines_left {
		position: absolute;
		left: 0;
		top: 10%;
	}

	.video_slider {
		margin-bottom: -10rem;
	}

	.swiper-pagination.video_pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
		top: 11rem;
	}

	.vimeo_wrapper {
		margin-top: 0;
	}
}

.slido_wrapper {
	padding: 2rem 2rem;
    border-radius: 20px;
    margin-top: -8rem;
    margin-left: 5rem;
    margin-right: 5rem;
    z-index: 999;
    position: relative;
    min-height: 1000px;
    height: 1000px;
    background-color: white;
}

.itinerary_section {
	
	.scrolling_section {
		border-left: 2px solid #16E7CF;
		padding-top: 8rem;
	}
	
	.stickthis {
		padding-top: 8rem;
	}
	.item {
		min-height: 10vh;
		margin-left: 8rem;
		position: relative;
		margin-bottom: 65px;
		
		&:before {
			content: "";
			width: 5rem;
			position: absolute;
			left: -8rem;
			top: 20px;
			height: 2px;
			background-color: #16E7CF;
			display: block;
		}
	}
}