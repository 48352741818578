.trainee_header {
	padding-top: 16rem;
    padding-bottom: 10rem;
	position: relative;

	img.header_lines {
		position: absolute;
		right: 160px;
		bottom: -110px;
		z-index: 9990;
	}
}

.tp_intro {
	padding-top: 10rem;
}

.tp_points {
	padding-top: 5rem;

	.block_item {
		background: $navy;
		padding: 40px;
		border-radius: 8px;
		height: 100%;
	}
}

.tp_join {
    padding-top: 26rem;
    margin-top: -18rem;
    padding-bottom: 16rem;
}