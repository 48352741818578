.grey_banner {
    background: #F3F4F7;
    height: 110px;
    display: block;
    margin-bottom: -80px;
}

.consultant_banner {
    background: $primary;
    height: 110px;
    display: block;
    margin-bottom: -80px;
}

.feedback_header {
    
    .profile_wrapper {
        position: relative;
        display: block;
        width: 100%;
        max-width: 200px;
        max-height: 200px;
    }
    
    .rating_item {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background: #3279C6;
        border-radius: 20px;
        display: block;
        z-index: 9999;
        position: absolute;
        right: 40px;
        padding-top: 8px;
        transition: 1s;
    }
    
    .row {
        background: $primary;
        border-radius: 8px;
        padding: 30px;
        padding-bottom: 10px;
    }
    
    .profile_image {
        position: absolute;
        transition: 1s;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        margin-top: -55px;
    }
    
    .profile-avatar {
        position: absolute;
        transition: 1s;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        margin-top: 15px;
        line-height: 165px;
        background: #90b7e0;
        color: #fff;
        font-size: 50px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.consultant_feedback_header {
    
    .profile_wrapper {
        position: relative;
        display: block;
        width: 100%;
        max-width: 200px;
        max-height: 200px;
    }
    
    .rating_item {
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background: #3279C6;
        border-radius: 20px;
        display: block;
        z-index: 9999;
        position: absolute;
        right: 40px;
        padding-top: 8px;
        transition: 1s;
    }
    
    .header_content_wrapper {
        background: #E1E4EC;
        border-radius: 8px;
        padding: 30px;
        padding-bottom: 10px;
    }
    
    .profile_image {
        position: absolute;
        transition: 1s;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        margin-top: -55px;
    }
    
    .profile-avatar {
        position: absolute;
        transition: 1s;
        border-radius: 50%;
        width: 165px;
        height: 165px;
        margin-top: 15px;
        line-height: 165px;
        background: #90b7e0;
        color: #fff;
        font-size: 50px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.feedback_content {
    margin-top: 5rem;
}

.fz12 {
    font-size: 12px;
    line-height: 22px;
}

.rating_scale {
    background: #F3F4F7;
    padding: 50px 50px;
    border-radius: 8px;

    h5 {
        margin-bottom: 30px;
        float: left;
    }

    img {
        max-width: 16px;
    }
}

.rating_expander {
    font-size: 12px;
    color: #797979;
    text-decoration: none;
    float: right;
    background: transparent;
    border: none;
}

.decline_feedback {
    background: $primary;
    padding: 6px 20px;
    border-radius: 30px;
    font-size: 16px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    margin-left: 20px;
    border: 1px solid $primary;
    transition: 0.4s;

    &:hover {
        color: $primary;
        background: transparent;
    }
}

.tasks_rating {
    .container {
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        padding: 3rem;
    }
    
    .form-group {
        margin-bottom: 0!important;
        margin-top: 0 !important;
    }
    
    select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        color: #333333;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 95% 15px;
        width: 160px;
    }

    .feedback_item {
        background: #F3F4F7;
        border-radius: 8px;
        padding: 30px 40px;
        position: relative;

        .expand_rating {
            position: absolute;
            right: 40px;
            top: 36px;
        }

        button {
            background: transparent;
            border: none;
            text-align: left;
            width: 100%;
        }

        .star_rating {
            font-size: 18px;
            color: #3279C6;
        }

        .divider {
            background: #D9D9D9;
            height: 1px;
            width: 100%;
            display: block;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        p {
            font-size: 12px;
        }

        ol {
            padding-left: 15px;
        }

        ol, li {
            font-size: 12px;
            color: #797979;
        }
    }

    .btn_turquiose {
            
        font-size: 16px;
        font-weight: bold;
        background: #00E7CF;
        height: 40px;
        text-align: center;
        padding: 6px 46px 0px 46px;
        display: inline-block;
        color: $primary;
        text-decoration: none;
        border-radius: 20px;
        border: 1px solid #00E7CF;
        transition: 0.3s;

        &:hover {
            background: white;
        }
        
    }

    .btn_primary {
            
        font-size: 16px;
        font-weight: bold;
        background: $primary;
        height: 40px;
        text-align: center;
        padding: 6px 46px 0px 46px;
        display: inline-block;
        color: white;
        text-decoration: none;
        border-radius: 20px;
        border: 1px solid $primary;

        transition: 0.3s;

        &:hover {
            background: white;
            color: $primary;
        }
        
    }
}

.filter_select {


    .form-group {
        margin-bottom: 0!important;
        margin-top: 0 !important;
        float: right;
        margin-right: -13px;
    }

    select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        color: #333333;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 95% 15px;
        width: 300px;
    }
    
}

.other_feedback {
    margin-top: 6rem;
}

.otherfeedback_items {

    .of_items {
        background: #F3F4F7;
        border-radius: 8px;
        padding: 30px;

        h5 {
            font-size: 14px;
            margin-bottom: 0;
        }

        .action {
            font-size: 14px;
            color: white;
            background: #F28F16;
            border-radius: 30px;
            text-decoration: none;
            padding: 4px 24px;


        }
    }

    .green {
        background: #D5FBF7;

        .action {
            font-size: 12px;
            font-weight: 600;
            color: $primary;
            background: #16E7CF;
            border-radius: 30px;
            text-decoration: none;
            padding: 6px 26px;


        }
    }

}
.changing {
    background: #4A516B;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top: 4rem;
    
    position: relative;
    overflow: hidden;
    
    .btn_blue3 {
        
        font-size: 16px;
        font-weight: bold;
        background: $blue3;
        height: 40px;
        text-align: center;
        padding: 6px 46px;
        display: inline-block;
        color: white;
        text-decoration: none;
        border-radius: 20px;
        
    }
    
    .map_image {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

#add_comments {
    min-height: 83px;
}

.star_rating {
    z-index: 9999!important;
    position: relative;
}

.feedback_item {
    height: 100%;

    .answer {
        height: 120px;
    }
}