$primary:			#131935;
$palebluegrey:		#E1E4EC;
$paleblue:			#C8EEFE;
$turquoise:			#00E7CF;
$blue1:				#05A4CA;
$blue2:				#277AC2;
$blue3:				#216AAA;
$blue4:				#004C7F;
$navy:				#074269;
$deepnavy:			#131935;
$bluegrey:			#4A5169;
$offbluegrey:		#717586;
$yellow:			#FFBB45;
$lightorange:		#F98D30;
$orange:            #F28F16;
$deeporange:		#FF5C3E;
$red:				#FD2C37;
$paleorangered:		#D14441;
$plum:				#9C2D41;
$lightgrey:				#f2f2f2;

// New Colors????

$bluenew: #3279C6;