.intl_banner {
    height: 80vh;
    position: relative;
	margin-top: 7rem;
}

.why_points_overlap_intl {
	
	margin: auto;
	left: 0;
	right: 0;
    padding-top: 10rem;
    padding-bottom: 4rem;

    background: #131936;
	
	.point_wrapper {
		background: #074269;
		border-radius: 8px;
		padding: 2rem;
		height: 100%;
	}

	.text-link {
		text-decoration: none;
	}
}

#its-not-too-late {
	img.header_lines.d-none.d-lg-block {
		bottom: -110px;
	}
}