.mt-6 {
	margin-top: 5rem;
}
.mb-6 {
	margin-bottom: 5rem;
}

.pt-6 {
	padding-top: 5rem;
}
.pb-6 {
	padding-bottom: 5rem;
}

.p-6 {
	padding: 5rem;
}

.mt-7 {
	margin-top: 7rem;
}
.mb-7 {
	margin-bottom: 7rem;
}

.pt-7 {
	padding-top: 7rem;
}
.pb-7 {
	padding-bottom: 7rem;
}

.p-7 {
	padding: 7rem;
}

.py-7 {
	padding-bottom: 6rem;
	padding-top: 6rem;
}

.mt-10 {
	margin-top: 10rem;
}
.mb-10 {
	margin-bottom: 10rem;
}

.pt-10 {
	padding-top: 10rem;
}
.pb-10 {
	padding-bottom: 10rem;
}

.p-10 {
	padding: 10rem;
}

.py-10 {
	padding-bottom: 10rem;
	padding-top: 10rem;
}

@media (max-width: 992px) {
	.pb-10 {
		padding-bottom: 5rem;
	}
	.pt-10 {
		padding-top: 5rem;
	}
}
