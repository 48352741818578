.saica_header {
    position: relative;
    .wrapper {
        margin-top: 3rem;
        padding-top: 26rem;
        padding-bottom: 5rem;

        @media (max-width: 992px) {
            padding-top: 10rem;
        }
    }

    @media (max-width: 992px) {
        
          p {
            font-size: 14px;
          } 

          h5 {
            font-size: 18px;
          }
    }
}

.saica_lines {
    position: absolute;
    right: 0px;
    bottom: -10%;
}

.saica_engage {
    padding-top: 7rem;
    padding-bottom: 7rem;
    .container {
        position: relative;
    }

    img {
        position: absolute;
        right: 0;
        top: -80px;
    }
}

.saica_lines_001 {
    position: absolute;
    top: -20%;
    right: -15%;
    z-index: 10;
}

.saice_image_zindex {
    z-index: 99;
    position: relative;
}

.saica_lines_002 {
    position: absolute;
    bottom: -50px;
    left: -50px;
    z-index: 9999;
}
.interested_items {
    position: relative;
}
.interested_items a {
    text-decoration: none;
}

.interested_item {
    min-height: 220px;
    border-radius: 16px;
    padding: 20px 40px;
    z-index: 99;
    position: relative;
}

.interested_background {
    position: absolute;
    bottom: 0;
    left: -200px;
    z-index: 10;
}

@media (max-width: 992px) {

    .saica_engage img {
        display: none;
    }

    .saica_lines_002 {
        position: absolute;
        bottom: -80px;
        left: -80px;
        z-index: 9999;
    }
}