// Specific grid adjustments

.row.gutter_md > .col,
.row.gutter_md > [class*="col-"] {
	padding-right: 45.2px;
	padding-left: 45.2px;

	&:first-of-type {
		padding-left: 0;
	}

	&:last-of-type {
		padding-right: 0;
	}
}

.row-nogutter {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.row-nogutterpadding {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

@media (min-width: 1200px) {
	.container_xl {
		max-width: 1280px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (max-width: 1200px) {
	.container_xl {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 667px) {
	.container_xl {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}
