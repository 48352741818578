@import 'global/global_main.scss';

@import 'layouts/_home.scss';
@import 'layouts/_contact.scss';
@import 'layouts/_engage.scss';
@import 'layouts/_praticeareas.scss';
@import 'layouts/_calc.scss';
@import 'layouts/_about.scss';
@import 'layouts/_savings_calc.scss';
@import 'layouts/_webinar.scss';
@import 'layouts/_visionplanner.scss';
@import 'layouts/_team.scss';
@import 'layouts/_join.scss';
@import 'layouts/_blog.scss';
@import 'layouts/_applicants.scss';
@import 'layouts/_impact.scss';
@import 'layouts/v2updates.scss';
@import 'layouts/reports.scss';
@import 'layouts/error.scss';
@import 'layouts/trainee-program.scss';
@import 'layouts/tas.scss';
@import 'layouts/busyseason.scss';
@import 'layouts/profiles.scss';
@import 'layouts/intl.scss';
@import 'layouts/topemployers.scss';
@import 'layouts/saica.scss';
@import 'layouts/ebp_new.scss';

@import 'layouts/_sheduler_modal.scss';
@import 'layouts/_feedback_module.scss';

@import 'global/responsive.scss';


.ar_block {
	height: 100%;
}
