.qs_item {
    border-radius: 16px;
    background: linear-gradient(177deg, rgba(255, 255, 255, 0.06) 2.09%, rgba(255, 255, 255, 0.00) 97.91%);
    padding: 20px;
}

.epb_cta {
    border: 1px solid #5A5E72;
    border-radius: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
    
    position: relative;
    
    .target_image {
        position: absolute;
        right: 20px;
        bottom: -20px;
    }
}

.ebp_table {
    background: #1A203C;
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;
    
    margin-top: 40px;
    padding-bottom: 40px;
    
    .pop_tag {
        background: #05A4CA;
        border-radius: 8px;
        font-size: 12px;
        color: white;
        font-family: "Inter", sans-serif;
        padding: 4px 16px;
        display: inline-block;
        font-weight: 600;
        margin-bottom: 20px;
        position: absolute;
        top: 20px;
    }
}

.ebp_tableblank {
    background: transparent;
}

.ebp_table.highlighted {
    border: 1px solid #16E7CF;
}

.table_head {
    min-height: 320px;
    padding-top: 60px;
    position: relative;
    border-bottom: 1px solid #4A516B;
}

.table_item {
    
    border-bottom: 1px solid #4A516B;
    padding-top: 6px;
    
    &:last-of-type {
        border-bottom: none;
    }
    li {
        color: white;
        font-size: 16px;
        @media (max-width:992px) {
            font-size: 14px;
        }
    }
}

.cz_1 {
    min-height: 44px;
    p {
        margin-bottom: 0;
    }
}

.cz_2 {
    min-height: 68px;
    p {
        margin-bottom: 0;
    }
}

.cz_3 {
    min-height: 190px;
    p {
        margin-bottom: 0;
    }
}

.coe_item {
    background: $primary;
    border-radius: 24px;
    padding: 20px 36px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    
    h5 {
        margin-bottom: 0;
    }
    
    img {
        max-height: 80px;
    }
}

.bg_gradient_accent {
    display: block;
    position: absolute;
    max-width: 700px;
    width: 100%;
    height: 120px;
    border-radius: 16px;
    background: linear-gradient(260deg, #16E7CF -6.47%, #2B6AAD 88.97%), #D9D9D9;
    z-index: -1;
    left: 0;
    right: 0;
    margin: auto;
    top: 25%;
}

.coe_bar {
    display: block;
    background: #2B304A;
    height: 11px;
    width: 100%;
    border-radius: 16px;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    
    .inner {
        border-radius: 16px;
        background: linear-gradient(260deg, #16E7CF -6.47%, #2B6AAD 88.97%), #D9D9D9;
        display: block;
        position: absolute;
        left: 0;
        width: 20px;
        height: 11px;
        transition: 8s;
    }
}

.coe_itembar {
    opacity: 0.4;
    transition: 0.6s;
}

.coe_active {
    opacity: 1;
}

.mcoe_innerfull {
    width: 100%!important;
}

.case_study_wrapper {
    border-radius: 24px;
    background: rgb(19,25,54);
    background: linear-gradient(0deg, rgba(19,25,54,1) 8%, rgba(22,231,207,0.4416141456582633) 100%);
    padding: 24px 32px;
    
    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
}

.swiper-ebp {
    
    height: 230px;
    background: white;
    border-radius: 24px;
    padding: 24px 86px;
    
    .swiper-slide {
        display: flex;
        align-items: center;
    }
    
    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 5px;
        background: #555c74;
        opacity: 1;
    }
    
    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #3279c6 !important;
    }
    
    
}

.swipertestsection {
    padding-bottom: 30rem;
}

#employee-benefit-plan {
    .pa_contact_single {
        margin-top: -20rem;
        padding-top: 7rem;
        background: transparent !important;
    }
}

.ebp_mobilechart {
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .ebp_hero {
        padding-bottom: 6rem;
    }
    
    .coe_item h4.sm {
        font-size: 16px;
        line-height: 28px;
    }
    
    .epb_cta .target_image {
        position: absolute;
        right: 20px;
        bottom: -20px;
        max-width: 160px;
    }
    
    .swiper_wrapper_ebp {
        background: white;
        padding: 20px;
        border-radius: 24px;
    }
}

@media (max-width:992px) {
    .swiper-ebp {
        height: 400px;
    }
    
    .pa_blocks_wrapper_single {
        background-color: #F6F6F6;
        padding-top: 20rem;
        margin-top: -21rem;
    }
    
    .table_head {
        min-height: fit-content;
        padding-top: 60px;
        padding-bottom: 30px;
        position: relative;
        border-bottom: 1px solid #4A516B;
    }
    
}

@media (max-width:762px) {
    .latfb {
        margin: 0%;
    }
    
    .table_head {
        min-height: fit-content;
        padding-top: 60px;
        position: relative;
        border-bottom: 1px solid #4A516B;
        padding-bottom: 20px;
    }
    
    section.background-primary.pb-5.py-10.pb-xl-10.mt-6.mt-md-10.mcoe_holder {
        padding-top: 5rem;
    }
}

.swiper_wrapper_ebp.d-md-none {
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 0px;
        left: 0;
        width: 100%;
        position: relative;
    }
}

.swiper-ebp h5,
.swiper-ebpmobile h5 {
    font-weight: 400!important;
}

.swiper-ebp {
    .img-fluid {
        max-width: 180px;
    }
}

.swiper-ebpmobile {
    
    .swiper-slide {
        height: auto;
    }
}

.ebp_mobilechart {

    margin-top: 40px;
    
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        display: none;
    }
    
    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 70px;
        width: 20px;
        height: 30px;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $turquoise;
    }
    
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        right: 0;
        left: unset;
    }
    
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        left: 0;
        right: unset;
    }
    
    .naviwrapper {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: -60px;
    }
    
}