.contact_hero {
	// height: 90vh;
	padding-top: 36vh;
    padding-bottom: 46vh;
	@media (max-width: 768px) {
		background-attachment: initial!important;
		padding-top: 25vh;
		padding-bottom: 24vh;
	}
}

.contact_form {
	
	position: absolute;
	margin: auto;
	width: 100%;
	left: 0;
	right: 0;
	top: -36vh;

	@media (max-width: 768px) {
		top: -26vh;
		position: relative;
	}
	
	.contact_form_wrapper {
		background: white;
		border-radius: 16px;
		padding: 4rem;
		z-index: 99;
		position: relative;
	}
}

.box_item:first-of-type {
	&:before {
		content: "";
		width: 300px;
		height: 3px;
		background-color: white;
		display: block;
		position: absolute;
		transform: rotate(40deg);
		left: -266px;
		top: -3rem;
	}
}

.box_item {
	border: 3px solid white;
	padding: 0px 40px;
	border-radius: 16px;
	width: fit-content;
	position: relative;
	
	.number {
		font-size: 60px;
		color: white;
		font-family: 'Inter', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-weight: 700;
		margin-right: 30px;
	}
	
	p {
		margin-bottom: 0px;
	}
}

.box_item.no_border {
	border: 3px solid transparent;
}

.contact_buttons {
	margin-top: -6rem;
}

@media (max-width: 768px) {

	.target_contact_content	{
		margin-top: -9rem;
		z-index: 9999;
		position: relative;
	}


	.box_item:first-of-type:before {
		display: none;
	}

	.contact_buttons {
		margin-top: 3rem;
		text-align: center;
	}

}