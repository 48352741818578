.engage_hero {
	padding-top: 17rem;
}

.engage_hero_short {
    padding-top: 11rem;
}

.engage_section_two {
	padding-top: 12rem;
	margin-top: -7rem;
	padding-bottom: 6rem;
}

.vimeo_engage {
	margin-top: 2rem;
}

.mb_10 {
	margin-bottom: 20rem;
}

.scroller_item {
	
	.number {
		font-size: 7rem;
		color: #E1E4EC;
		font-weight: 700;
		line-height: normal;
		margin-top: -20px;
	}
	h5 {
		font-size: 22px;
		line-height: 32px;
	}
	
	p {
		font-size: 16px;
		line-height: 24px;
		margin-top: 22px;
	}
}

section#scrollerWaypoint {
	padding-top: 14rem;
	position: relative;
}

.engage_testimonials {
	#testimonials {
		padding-top: 18rem;
		padding-bottom: 12rem;
		margin-top: -8rem;
	}
}

#updates_engage {
	margin-top: -3rem;
	position: relative;
	
	@media (max-width: 768px) {
		margin-top: 3rem;
	}
	
	.row {
		height: 80vh;
		
		@media (max-width: 768px) {
			height: auto;
		}
	}
	
	img.background_image {
		position: absolute;
		right: 0;
		top: 0;
		max-width: 50%;
		
		@media (max-width: 768px) {
			position: relative;
			max-width: 100%;
		}
	}
}

.redwrap {
	position: relative;
}

.set100vh {
	height: 100vh;
}

.icon_background {
	position: absolute;
	height: 110vh;
	margin-top: -226px;
}

// .stickthis {
// 	@media (max-width:768px) {
// 		height: 0;
// 	}
// }

img.engage_lines {
    position: absolute;
    z-index: 9999;
    top: 60px;
    right: 100px;
}
.bluearrows:after {
    color: #0d4269!important;
    font-size: 22px!important;
}

@media (max-width: 992px) {

	
	
	.engage_testimonials #testimonials {
		padding-top: 5rem;
    padding-bottom: 6rem;
    margin-top: 5rem;
	}

	.comparison .third_col .bg_head {
		background: #F2F2F2;
		padding: 20px;
		text-align: center;
		width: 100%;
		border-radius: 20px 20px 0 0;
	}

}
