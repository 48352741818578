// Color System

$white:    #fff;
$gray-200: #F2F2F2;
$gray-300: #DEDEDE;
$gray-400: #D6D5D5;
$gray-500: #999999;
$gray-700: #797979;
$gray-900: #555555;
$black:    #333333;

$blue-100: #2B6AAD;
$blue-200: #3279C6;
$blue-300: #05A4CA;
$blue-400: #1C6EA6;
$blue-500: #02446E;


$theme-colors: (
  "primary":          	#131936,
  "secondary":        	#004C7F,
  "secondary-hover":  	$blue-100,
  "accent":           	#16E7CF,
  "danger":           	#EB7C63,
  "blue":				        #05A4CA,
  "blue-grey":			    #4A516B,
  "blue-4":			$blue-400,
  "blue-5":			$blue-500,
);
