// Colors

.background-primary{ background: $primary;}
.background-palebluegrey{	background: $palebluegrey;}
.background-paleblue{ background: $paleblue;}
.background-turquoise{ background: $turquoise;}
.background-blue1{ background: $blue1;}
.background-blue2{ background: $blue2;}
.background-blue3{ background: $blue3;}
.background-blue4{ background: #004C7F;}
.background-navy{ background: $navy;}
.background-deepnavy{ background: $deepnavy;}
.background-bluegrey{ background: $bluegrey;}
.background-yellow{ background: $yellow;}
.background-lightorange{ background: $lightorange;}
.background-deeporange{ background: $deeporange;}
.background-red{ background: $red;}
.background-paleorangered{ background: $paleorangered;}
.background-plum{ background: $plum;}
.background-grey { background: #f2f2f2}
.background-lightgrey{ background: $lightgrey;}
.background-orange{ background: $orange;}

.background-bluenew{ background: $bluenew;}

.background_gradient {
    background: linear-gradient(90deg, #16E7CF -21.81%, #009BC8 100%);
}


main {
	overflow: hidden;
    position: relative;
}

// main {
// 	overflow: clip;
// }