body#tas {
    background: #F6F6F6;
}

.tas_next_section {
    margin-top: -14rem;
    padding-bottom: 20rem;
    position: relative;

    @media (max-width: 992px) {
        margin-top: -14rem;
        text-align: center;
        padding-bottom: 16rem;
    }
    img.lines_left {
        position: absolute;
        left: -120px;
        top: 13%;
    }
    
}

.tas_snippet {
    margin-top: 12rem;

    @media (max-width: 992px) {
        margin-top: 9rem;
        margin-bottom: 60px;
    }
}

.contactsection_text {
    padding-top: 10rem;
}

.tas_form {
    .contact_form_wrapper {
        background: white;
        padding: 4rem;
        border-radius: 8px;
        margin-top: -27rem;
        

        @media (max-width: 992px) {
            padding: 20px;
            margin-top: -3rem;
        }
    }

    .box-shadow {
        box-shadow: 0px 11px 21px 2px rgb(0 0 0 / 56%);
        -webkit-box-shadow: 0px 11px 21px 2px rgb(0 0 0 / 56%);
        -moz-box-shadow: 0px 11px 21px 2px rgb(0 0 0 / 56%);
    }
}

.tas_hero {
    @media (max-width: 992px) {
        background: #131935!important;
    }
}

.tas_contact {
    margin-top: -10rem;
    z-index: 99;
    position: relative;
}