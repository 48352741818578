.modal_wrapper_main {
    background: $primary;
    
    .h-100vh {
        height: 100vh;
    }
}

.tooltip {
    z-index: 100000000; 
}

.sheduler_modal {
    
    button.btn_close_x {
        background: transparent;
        border: none;
        position: absolute;
        right: 30px;
        top: 30px;
        color: #216aaa;
    }
    
    
    .modal-header {
        border-bottom: none;
        background: #F2F2F2;
        padding: 30px 40px;
        
        .info_blocks_wrapper {
            
            .info_block {
                border: 1px solid #C4C4C4;
                padding: 8px 24px;
                border-radius: 8px;
                
                p {
                    font-size: 12px;
                    margin-bottom: 0;
                }

                span {
                    margin-left: 8px;
                }
            }
        }
    }
    
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 30px 40px;
        
        .btn_icon_tooltip {
            background: transparent;
            border: none;
            color: black;
            
            .size-16 {
                width: 16px;
                height: 16px;
            }
        }
        
        .error_message {
            background: #FBE5E0;
            border: 1px solid #EB7C63;
            border-radius: 4px;
            text-align: center;
            padding: 10px 20px;
            p {
                color: #EB7C63;
                font-size: 13px;
                margin-bottom: 0;
            }
        }

        
        .date_item {

            position: relative;
            
            .date_range {
                font-size: 12px;
                color: #2B6AAD;
                margin-bottom: 4px;
            }
            

            .locked {
                position: absolute;
                right: 6px;
                top: 32px;
                color: #999999;
                width: 10px;
                height: 10px;
            }
            
            
            .time_input {
                border: 1px solid #C4C4C4;
                font-size: 24px;
                font-weight: bold;
                color: black;
                width: 100%;
                border-radius: 8px;
                text-align: center;
                height: 52px;
            }
            
            .error {
                color: #EB7C63;
                border: 1px solid #EB7C63;
            }
        }

        .locked_iem {
            border: 1px solid #16E7CF!important;
            background: #D3FBF6;
        }
        
        .load_more_weeks {
            color: #999999;
            font-size: 12px;
            text-decoration: none;
            transition: 0.3s;
            
            &:hover {
                color: #004C7F;
            }
        }
        
        .overview_wrapper {
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            
            
            p {
                font-size: 12px;
                line-height: 20px;
            }
            
            .block_001 {
                width: 40%;
                border-right: 1px solid #C4C4C4;
                padding-left: 20px;
                padding-top: 16px;
                padding-bottom: 16px;
            }
            
            .block_002 {
                width: 30%;
                border-right: 1px solid #C4C4C4;
                padding-left: 20px;
                padding-top: 16px;
                padding-bottom: 16px;
            }
            
            .block_003 {
                width: 30%;
                padding-left: 20px;
                padding-top: 16px;
                padding-bottom: 16px;
            }
            
        }
        
        .total_wrapper {
            background: #004C7F;
            padding-left: 40px;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 8px;
            
            p {
                font-size: 12px;
                line-height: 16px;
            }
            
            .total_text {
                font-size: 26px;
            }
        }

        .total_wrapper.error {
            background: #EB7C63;
        }
    }
    
    .btn_submit {
        background: white;
        border: 1px solid white;
        color: #216aaa;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        padding: 6px 10px;
        float: right;
        position: absolute;
        right: 80px;
        transition: 0.3s;

        &:hover {
            background: #004C7F;
            color: white;
        }
    }

    .btn_submit.disabled {
        background: #D6D5D5;
        border: 1px solid #D6D5D5;
        color: #555;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        padding: 6px 10px;
        float: right;
        position: absolute;
        right: 80px;
        transition: 0.3s;

        &:hover {
            background: #D6D5D5;
            color: #555;
            cursor: not-allowed;
        }
    }
}
