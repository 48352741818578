.busyseason_header {
    
    padding-top: 10rem;
    padding-bottom: 6rem;
    position: relative;
    margin-top: 5rem;
    
    .accent_image {
        width: 100%;
        position: absolute;
        left: -50px;
        max-width: fit-content;
        top: 0;
        display: block;
        max-width: 490px;
        height: 100%;
    }
}

.result_item img {
    border-radius: 50%;
}

.busyseason_works {
    padding-top: 8rem;
    position: relative;
    
    .box_content {
        border-radius: 8px 8px 0 0;
        background: #004C7F;
        padding: 40px 30px;
        height: 100%;
        min-height: 300px;
    }
    
    .busysceen {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        max-width: 260px;
    }
}

.logos_wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
    
    h3 {
        font-size: 24px;
    }
    
    h5 {
        font-size: 16px;
        line-height: 25px;
    }
    
    p {
        font-size: 12px;
        line-height: 16px;
    }
}

img.busysceen {
    position: absolute;
    right: 10vw;
    top: -80px;
    z-index: 9999;
}

.find_section {
    padding-top: 8rem;
    padding-bottom: 8rem;
    overflow: hidden;
    
    position: relative;
    
    .number {
        font-size: 26vw;
        color: white;
        font-weight: bold;
        position: absolute;
        top: -27%;
        left: -5%;
    }
}

.results_wrapper {
    padding-top: 5rem;
}

.sidebar_filters {
    background: #F8F8F9;
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
}

html body .selectize-control.single .selectize-input > span {
    cursor: pointer;
    margin: 0 10px 0px 0;
    padding-top: 2px;
    padding-right: 25px !important;
    padding-bottom: 2px;
    padding-left: 12px;
    background: #004c7f !important;
    color: #fff !important;
    border: 0 solid #d0d0d0 !important;
    border-radius: 4px;
    margin-bottom: 10px;
}

html body .selectize-control.plugin-remove_button .remove-single {
    position: absolute;
    font-size: 20px;
    color: white;
    font-weight: 600;
    right: 6px;
    top: 1px;
    text-decoration: none;
}


.selectize-input {
    min-height: 64px !important;
    line-height: 28px;
    color: #333333 !important;
    padding: 16px !important;
    border: 1px solid #d6d5d5 !important;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
    color: #303030;
    font-size: 14px;
}

.selectize-control.multi .selectize-input > div {
    cursor: pointer;
    margin: 0 10px 0px 0;
    padding-top: 2px;
    padding-right: 25px !important;
    padding-bottom: 2px;
    padding-left: 12px;
    background: #004c7f !important;
    color: #fff !important;
    border: 0 solid #d0d0d0 !important;
    border-radius: 4px;
    margin-bottom: 10px;
}

.selectize-control.multi .selectize-input.has-items .active {
    background: #131936 !important;
    color: #fff !important;
    border: 0 solid #131936 !important;
    
    .remove {
        color: #16e7cf !important;
    }
}

.feather-14{
    width: 14px;
    height: 14px;
}

.feather-32{
    width: 32px;
    height: 32px;
}

.result_item {
    background: #F8F8F9 ;
    border-radius: 8px;
    padding: 30px;
    margin-top: 40px;
    
    .rating {
        font-size: 14px!important;
        color: #004C7F;
        margin-left: 20px;
        
        span {
            margin-left: 8px;
        }
    }
    
    .name {
        display: flex;
        line-height: 0;
    }
    
    
    .tag_lightblue {
        background: #E1E4EC;
        border-radius: 4px;
        padding: 6px 16px;
        font-size: 12px;
        color: #131936;
    }
    
    .text_small {
        font-size: 12px;
        color: #797979;
        margin-bottom: 0;
        line-height: 0;
        padding-top: 20px;
    }
    
    .learn_more_link {
        background: #004C7F;
        border-radius: 4px;
        padding: 6px 16px;
        font-size: 12px;
        color: white;
        transition: 0.3s;
        text-decoration: none;
        margin-left: 20px;
        border: none;
        
        &:hover {
            
            background: #E1E4EC;
            color: #131936;
            
        }
    }
    
    .stroke_tag {
        color: #004C7F;
        border: 1px solid #004C7F;
        font-size: 12px;
        padding: 4px 8px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 28px;
        margin-bottom: 12px;
    }
    
    .box_tags {
        .item {
            color: #333333;
            border: 1px solid #D6D5D5;
            font-size: 12px;
            padding: 4px 8px;
            margin-right: 10px;
            display: inline-block;
            border-radius: 4px;
            margin-bottom: 12px;
        }
    }
}

.banner_wrapper {
    background: #131936;
    padding: 50px 30px;
    border-radius: 8px;
    position: relative;
    
    margin-bottom: 4rem;
    margin-top: 3rem;
    
    img {
        position: absolute;
        left: -2rem;
    }
}

.IMD_section {
    
    .idm_block {
        padding: 8%;
        padding-top: 13rem;
        position: relative;
        overflow: hidden;
        
        h2 {
            font-size: 130px;
            font-weight: bold;
            position: absolute;
            top: 18%;
            left: -45px;
        }
        
        h4 {
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    
    .first_block {
        background: #E1E4EC;
        
        h2 {
            color: white;
        }
        
        h4 {
            color: #131936;
        }
    }
    
    .second_block {
        background: #4A516B;
        
        h2 {
            color: #131936;
        }
        
        h4 {
            color: white;
        }
        
        p {
            color: white;
        }
    }
    
    .third_block {
        background: #131936;
        
        h2 {
            color: #4A516B;
        }
        
        h4 {
            color: white;
        }
        
        p {
            color: white;
        }
    }
    
}


.results_wrapper {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 85vw;
            margin-right: auto;
            margin-left: auto;
        }
    }
    
    .modal-header {
        padding: 5rem 5rem 0 5rem;
        border: none;
        display: block;
        position: relative;
    }
    
    button.btn-close {
        position: absolute;
        right: 40px;
        top: 40px;
    }
    
    .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
        width: 100%;
        text-align: start;
        padding: 20px;
        border: 1px solid #D6D5D5;
        color: #131936;
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: var(--bs-modal-padding);
        padding: 5rem;
    }
    
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3780C2;
        font-weight: bold;
    }
    
    .marker {
        background: white;
        border: 2px solid #131936;
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 16px;
        border-radius: 30px;
    }
    
    .active .marker {
        background: white;
        border: 2px solid white;
        box-shadow: 0px 0px 0px 2px rgba(55,128,194,0.75) inset;
        -webkit-box-shadow: 0px 0px 0px 2px rgba(55,128,194,0.75) inset;
        -moz-box-shadow: 0px 0px 0px 2px rgba(55,128,194,0.75) inset;
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 16px;
        border-radius: 30px;
    }
    
    .nav-fill .nav-item {
        margin-right: 30px;
    }
}

.bs_lines_one {
    position: absolute;
    right: -30px;
    bottom: -100px;
    z-index: 999;
}

// .vimeo_wrapper {
//     margin-top: unset;
//     position: absolute;
//     top: 33vh;
// }



.active>.page-link, .page-link.active {
    z-index: 3;
    background-color: $primary!important;
    border-color: $primary!important;
}

.fimd_sect {
    padding-top: 6rem;
    padding-bottom: 6rem;
    
    position: relative;
    
    .fimd_rocket {
        position: absolute;
        right: 20%;
        max-width: 360px;
        bottom: -140px;
    }
    
    
}

.fimd_testimonals {
    padding-top: 12rem;
    padding-bottom: 8rem;
    
    .divider_blue {
        width: 35px;
        height: 3px;
        display: block;
        background-color: #3780C2;
    }

    .swiper-pagination {
        text-align: left;
    }
}

.mb_lg {
    margin-bottom: 6rem;
}

.mt-lg {
    margin-top: 6rem;
}

.busy_season_footer {
    padding-top: 6rem;
    position: relative;

    .footer_link {
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        margin-bottom: 40px;
        display: block;
    }

    .message {
        position: absolute;
        right: 0;
        bottom: 0;
    }

}

@media (max-width: 667px) {
    .mt-lg {
        margin-top: 1rem;
    }

    .fimd_sect {
        .divider_gradient {
            margin-left: 0;
        }
    }

    .fimd_sect .fimd_rocket {
        display: none;
    }

    .fimd_sect {
        padding-bottom: 1rem;
    }

    .fimd_testimonals {
        padding-top: 5rem;
        padding-bottom: 5rem;

        .divider_gradient {
            margin-left: 0;
        }
    }

    .busy_season_footer .message {
        position: relative;
    max-width: 90%;
    margin-top: -3rem;
    margin-bottom: -3rem;
    }
}

.ppwrapper {
    max-width: 110px;
    max-height: 110px;
    overflow: hidden;
    border-radius: 50%;
}


.post_footer_busyseason {
	background: $primary;
	padding-bottom: 27px;

	.container {
		border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
		padding-top: 27px
	}

	p, span {
		font-size: 14px;
		display: inline-block;
	}

	p {
		padding-right: 50px;

		@media (max-width: 667px) {
			padding-right: 0px;
			margin-bottom: 16px;
		}
	}

	span {
		padding-left: 20px;
		padding-right: 20px;
	}

	a {
		color: white;
		opacity: 1;
		transition: 0.3s;
		font-size: 14px;
		display: inline-block;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
			cursor: pointer;
		}
	}

	.sm_links {

		.oneplanent {
			img {
				width: 100%;
				opacity: 1;
			}
		}

		a {
			transition: 0.3s;
			img {
				opacity: 0.4;
				transition: 0.3s;
			}
			@media (min-width: 667px) {
				padding-left: 25px;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		@media (max-width:667px) {
			img, a {
				width: 15%;
				text-align: center;

				img {
					width: auto;
				}
			}
		}
	}


}