.team_hero {
	padding-top: 14rem;
	padding-bottom: 7rem;
}

.team_spacing {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.team_card {
	border-radius: 8px;
	height: 100%;

	.text-link--secondary {
		color: #3279C6;
		text-decoration: none;
	}

	.text-link--white {
		color: #ffffff;
		text-decoration: none;
	}

	h5 {
		line-height: 24px;
	}
}