.hero_banner {
	height: 90vh;
	position: relative;
	
	@media (max-width: 768px) {
		background-attachment: initial!important;
	}
	
	.text_block {
		padding-top: 5rem;
	}
}

.swiper-home {
	.swiper-pagination {
		bottom: 110px;
	}
	
	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		display: inline-block;
		border-radius: 5px;
		background: transparent;
		opacity: 1;
		border: 1px solid white;
	}
	
	.swiper-pagination-bullet-active {
		border: 1px solid #16E7CF;
	}
	
	.img_vector {
		margin-top: 24%;
	}
	
	.swiper-button-next:after, .swiper-button-prev:after {
		font-size: 26px;
	}
	
	// @media (min-width: 1441px) {
	// 	.swiper-button-next, .swiper-rtl .swiper-button-prev {
	// 		right: 10%;
	// 		left: auto;
	// 	}
		
	// 	.swiper-button-prev, .swiper-rtl .swiper-button-next {
	// 		left: 10%;
	// 		right: auto;
	// 	}
	// }
	
	
}

.wrapper_box {
	padding: 7rem 5rem;
	border-radius: 20px;
	margin-top: -10rem;
	z-index: 999;
	position: relative;
	@media (max-width: 992px) {
		padding: 3rem;
		z-index: 9999;
	}
	
	h5, h2 {
		margin-bottom: 3rem;
	}
}

img.header_lines {
	position: absolute;
	right: 160px;
	bottom: 120px;
	z-index: 9990;
}

.vimeo_wrapper {
	margin-top: -16rem;
	
	@media (max-width: 992px) {
		margin-top: 3rem;
	}
}

img.graphic_one {
	margin-top: 8rem;
	max-width: 100%;
	
	@media (max-width: 992px) {
		margin-top: 0rem;
		margin-bottom: -13rem;
		max-width: 100%;
		z-index: 9999;
		position: relative;
	}
}

.pa_blocks {
	
	.block_number {
		font-size: 7rem;
		font-weight: 800;
		color: white;
		opacity: 0.4;
		font-family: 'Inter', sans-serif;
		position: absolute;
		top: 30px;
		line-height: 0;
		transition: 0.4s;
	}
	
	.swiper-button-next:after, .swiper-button-prev:after {
		font-family: swiper-icons;
		font-size: var(--swiper-navigation-size);
		text-transform: none!important;
		letter-spacing: 0;
		font-variant: initial;
		line-height: 1;
		color: white;
	}
	
	.item {
		max-width: 80%;
		margin: auto;
		overflow: hidden;
		z-index: 9999;
		
		padding-top: 7rem!important;
		border-radius: 8px;
		max-height: 340px;
		transition: 0.4s;
		
		h5, p {
			transition: 0.4s;
		}
		
		.btn_primary {
			opacity: 0;
			transition: 0.4s;
		}
		
		&:hover {
			max-height: 400px;
			cursor: pointer;
			background: white!important;
			h5, p {
				color: $primary!important;
			}
			
			.btn_primary {
				opacity: 1;
			}
			
			.block_number {
				color: $palebluegrey;
				opacity: 1;
			}
		}
	}
}

.pa_blocks_mobile {
	
	.block_number {
		font-size: 7rem;
		font-weight: 800;
		color: white;
		opacity: 0.4;
		font-family: 'Inter', sans-serif;
		position: absolute;
		top: 30px;
		line-height: 0;
		transition: 0.4s;
	}
	
	.swiper-button-next:after, .swiper-button-prev:after {
		font-family: swiper-icons;
		font-size: var(--swiper-navigation-size);
		text-transform: none!important;
		letter-spacing: 0;
		font-variant: initial;
		line-height: 1;
		color: white;
	}
	
	.item {
		max-width: 80%;
		margin: auto;
		overflow: hidden;
		z-index: 9999;
		
		padding-top: 7rem!important;
		border-radius: 8px;
		max-height: 340px;
		transition: 0.4s;
		
		h5, p {
			transition: 0.4s;
		}
		
		.btn_primary {
			opacity: 0;
			transition: 0.4s;
		}
		
		max-height: 400px;
		cursor: pointer;
		background: white!important;
		h5, p {
			color: $primary!important;
		}
		
		.btn_primary {
			opacity: 1;
		}
		
		.block_number {
			color: $palebluegrey;
			opacity: 1;
		}
	}
}

.pa_blocks_large {
	
	.block_number {
		font-size: 7rem;
		font-weight: 800;
		color: white;
		opacity: 0.4;
		font-family: 'Inter', sans-serif;
		position: absolute;
		top: 30px;
		line-height: 0;
		transition: 0.4s;
	}
	
	.item {
		position: absolute;
		overflow: hidden;
		z-index: 9999;
		
		padding-top: 7rem!important;
		border-radius: 8px;
		max-height: 340px;
		transition: 0.4s;
		
		h5, p {
			transition: 0.4s;
		}
		
		.btn_primary {
			opacity: 0;
			transition: 0.4s;
		}
		
		&:hover {
			max-height: 400px;
			cursor: pointer;
			background: white!important;
			h5, p {
				color: $primary!important;
			}
			
			.btn_primary {
				opacity: 1;
			}
			
			.block_number {
				color: $palebluegrey;
				opacity: 1;
			}
		}
	}
}

#FloatZone001 {
	position: relative;
}

.star_section {
	padding-bottom: 24rem;
	
	@media (max-width: 992px) {
		padding-bottom: 10rem;
	}
}
.float_001 {
	position: absolute;
	display: block;
	right: -60px;
	top: 100%;
	transform: translate(-50%);
	z-index: 9999;
	transition: 2s;
}

.practice_areas {
	
	.pa_link {
		font-size: 18px;
		font-weight: 500;
		color: $primary;
		margin-bottom: 16px;
		transition: 0.4s;
		
		&:hover {
			cursor: pointer;
			color: $blue2;
		}
	}
	
	.active {
		color: $blue3;
		
		&:before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 2px;
			background-color: $blue3;
			margin-right: 10px;
		}
	}
	
	.content_block {
		position: absolute;
		overflow: hidden;
		transition: 0.4s;
		
		.number {
			font-size: 18rem;
			position: absolute;
			font-weight: 700;
			font-family: "Inter", sans-serif;
			color: white;
			opacity: 0.1;
			line-height: 0;
			left: -5rem;
			top: 50%;
		}
	}
	
	.shown {
		left: 0;
		transition: 0.4s;
	}
	
	.hidden {
		left: -100rem;
		transition: 0.4s;
		width: 900px;
	}
}

.benefits_section {
	
	padding-top: 10rem;
	padding-bottom: 22rem;
	
	.bars_wrapper {
		height: 460px;
		border-bottom: 2px solid #00B5D9;
		
		@media (max-width: 992px) {
			height: 430px;
		}
	}
	
	
	.first_col {
		margin: auto;
		text-align: center;
		.bar {
			margin: auto;
			height: 0px;
			background: #E1E4EC;
			opacity: 0.5;
			width: 60%;
			transition: 1s;
		}
		
		.bar_one_height {
			height: 120px;
			
			@media (max-width: 992px) {
				height: 80px;
			}
		}
	}
	
	.second_col {
		margin: auto;
		text-align: center;
		.bar {
			margin: auto;
			background: #E1E4EC;
			opacity: 0.5;
			width: 60%;
			height: 100%;
			height: 0px;
			transition: 1s;
		}
		.bar_two_height {
			height: 420px;
			
			@media (max-width: 992px) {
				height: 320px;
			}
		}
	}
	
}


.benefits_section_mobile {
	
	height: 100vh;
	
	
	.first_col {
		.bar {
			background: #E1E4EC;
			opacity: 0.5;
			width: 0;
			transition: 1s;
			height: 50px;
		}
		
		.bar_one_length {
			width: 30vw;
		}
	}
	
	.second_col {
		
		.bar {
			background: #E1E4EC;
			opacity: 0.5;
			width: 0;
			height: 50px;
			transition: 1s;
		}
		.bar_two_length {
			width: 80vw;
		}
	}
	
}

.comparison {
	
	.item {
		height: 70px;
		display: block;
		p {
			// line-height: 0;
			margin-bottom: 0;
		}
		
		div {
			display: inline-flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: flex-start;
			justify-content: flex-start;
			align-items: flex-start;
			
			img {
				margin-top: 2px;
			}
		}
	}
	
	.first_col {
		.content_wrapper {
			background: #3279C6;
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 30px;
			padding-bottom: 20px;
			border-radius: 20px 0 0 20px;
		}
	}
	
	.second_col {
		box-shadow: 1px 4px 16px 0px rgba(0,0,0,0.35);
		-webkit-box-shadow: 1px 4px 16px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 1px 4px 16px 0px rgba(0,0,0,0.35);
		border-radius: 20px;
		overflow: hidden;
		z-index: 99;
		
		.content_wrapper {
			background: #F2F2F2;
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 30px;
			padding-bottom: 60px;
			
		}
	}
	
	.third_col {
		
		border-radius: 20px;
		overflow: hidden;
		
		.bg_head {
			background: #F2F2F2;
			padding: 20px;
			text-align: center;
			width: 100%;
			border-radius: 0px 20px 0 0;
		}
		.content_wrapper {
			background: #F2F2F2;
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 30px;
			border-radius: 0 0 20px 0;
			
		}
	}
	
	
	.m_head {
		background: #F2F2F2;
		border-radius: 20px 20px 0px 0px;
		padding: 20px;
		padding: 30px 30px!important;
		text-align: center;
		
		img {
			width: 180px;
		}
	}
}

#movement {
	height: 580px;
	position: relative;
}

#updates {
	margin-top: 0rem;
	position: relative;
	padding-bottom: 3rem;
	
	@media (max-width: 992px) {
		margin-top: 28rem;
	}
	
	.row {
		height: 80vh;
		
		@media (max-width: 992px) {
			height: auto;
		}
	}
	
	img.background_image {
		position: absolute;
		right: -5%;
		top: 0;
		max-width: 50vw;
		width: 100%;
		
		@media (max-width: 992px) {
			display: none;
		}
	}
}

#testimonials {
	padding-top: 12rem;
	padding-bottom: 12rem;
	margin-top: -8rem;
	
	@media (max-width: 992px) {
		padding-top: 7rem;
		padding-bottom: 7rem;
	}
}

#blog_articles {
	background: #29324F;
	padding-top: 6rem;
	padding-bottom: 6rem;
}
#float_zone_one {
	position: relative;
}

.line_float_one {
	display: block;
	position: absolute;
	top: 100%;
	right: 70%;
	transition: 4s;
	
	max-width: 100%;
	z-index: 9999;
}

.growEase {
	top: -20rem;
	right: -20rem;
}

.line_float_two {
	display: block;
	position: absolute;
	top: 0%;
	left: 0%;
	transition: 4s;
	
	max-width: 100%;
}

.growEase_two {
	top: 300%;
	left: -100%;
}

.floatUp {
	top: 0;
}

@media (max-width: 992px) {
	body {
		overflow-x: hidden;
	}
}

img.cloud_float {
	position: absolute;
	bottom: -30px;
	left: 0;
	transition: 10s;
	z-index: 99999;
}

.float_right {
	left: 100%!important;
}

img.cloudtwo {
	position: absolute;
	bottom: -40px;
}

.practice_areas_mobile {
	padding-top: 5rem;
	padding-bottom: 5rem;
	background: #F0F3F9;
	
	.swiper-slide {
		background: #131936;
		padding: 3rem;
		border-radius: 16px;
		height: fit-content!important;
		padding-top: 16rem;
		overflow: hidden;
		
		@media (max-width: 420px) {
			width: 100%!important;
		}
		
		.number {
			color: white;
			font-size: 14rem;
			font-weight: 700;
			opacity: 0.3;
			left: -50px;
			top: -50px;
			position: absolute;
		}
	}
}



.comparison_csection {
	margin-top: -33vh;
	
	@media (max-width: 992px) {
		margin-top: 4rem;
	}
}

@media (max-width: 992px) {
	#benefits {
		height: 110vh;
	}
}

.swiper-pa_mobile {
	.pa_blocks .swiper-button-next:after, .pa_blocks .swiper-button-prev:after {
		font-family: swiper-icons;
		font-size: var(--swiper-navigation-size);
		text-transform: none !important;
		letter-spacing: 0;
		font-variant: initial;
		line-height: 1;
		color: white;
		font-size: 20px;
	}
}

.swiper-testimonials {
	.swiper-pagination {
		bottom: 10px!important;
	}
}

.header_dark .expand_titles {
	color: #131936;
}

.dropdown-item.active, .dropdown-item:active {
	color: var(--bs-dropdown-link-active-color);
	text-decoration: none;
	background-color: transparent;
}

.comparison .third_col .content_wrapper {
	padding-bottom: 30px;
}

#blog_articles {
	h5 {
		min-height: 65px;
	}
}

#blog .blog_link h6 {
	min-height: 35px;
}

.swiper-partnermobile {
	padding-bottom: 4rem;
}


.alliance_blocks_mobile {
	
	.block_number {
		font-size: 7rem;
		font-weight: 800;
		color: #336283;
		opacity: 0.4;
		font-family: 'Inter', sans-serif;
		position: absolute;
		top: 30px;
		line-height: 0;
		transition: 0.4s;
	}
	
	.swiper-button-next:after, .swiper-button-prev:after {
		font-family: swiper-icons;
		font-size: var(--swiper-navigation-size);
		text-transform: none!important;
		letter-spacing: 0;
		font-variant: initial;
		line-height: 1;
		color: $primary!important;
	}
	
	.item {
		max-width: 80%;
		margin: auto;
		overflow: hidden;
		z-index: 9999;
		
		padding-top: 7rem!important;
		border-radius: 8px;
		max-height: 340px;
		transition: 0.4s;
		
		h5, p {
			transition: 0.4s;
		}
		
		.btn_primary {
			opacity: 0;
			transition: 0.4s;
		}
		
		max-height: 400px;
		cursor: pointer;
		background: #074269!important;
		h5, p {
			color: white!important;
		}
		
		.btn_primary {
			opacity: 1;
		}
		
		.block_number {
			color: #336283;
			opacity: 1;
		}
	}
}