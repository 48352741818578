.box-shadow {
	box-shadow: 0px 7px 13px -8px rgb(0 0 0 / 56%);
    -webkit-box-shadow: 0px 7px 13px -8px rgb(0 0 0 / 56%);
    -moz-box-shadow: 0px 7px 13px -8px rgba(0, 0, 0, 0.56);
}

.overflow-hidden {
	overflow: hidden;
}

.z99 {
	z-index: 99;
	position: relative;
}

.disabled {
    cursor: not-allowed;
}

.cursor-pointer:hover {
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}


.selectize-height {
	height: 45px;
	line-height: 30px;
}

.br-4 {
	border-radius: 4px;
}
.br-30 {
	border-radius: 30px;
}

img.lines_left {
    position: absolute;
    left: 0;
    top: -20%;
}

img.lines_right {
    position: absolute;
    right: 0;
    top: -20%;
}