$block: '.hex-grid';

@mixin grid-item($amount) {
	@for $i from 1 through $amount {
		&:nth-of-type(#{$amount}n + #{$i}) {
			grid-column: #{$i + $i - 1} / span 3;
			@if $i % 2 == 0 {
				grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
			}
		}
	}

	@for $i from 1 through 10 {
		&:nth-of-type(n + #{$i * $amount + 1}) {
			--counter: #{$i + 1};
		}
	}
}


#{$block} {
	display: flex;
	justify-content: center;

	&__list {
		--amount: 7;
		position: relative;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
		grid-gap: 0rem 13px;

	}

	&__item {
		position: relative;
		grid-column: 1 / span 3;
		grid-row: calc(var(--counter) + var(--counter)) / span 2;
		filter: drop-shadow(0 0 10px rgba(#444, .08));
		height: 0;
		padding-bottom: 90%;

	}

	&__content {
		position: absolute;
		height: 100%;
		width: 90%;
		-webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
		clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 25%;
		text-decoration: none;
		text-align: center;
		font-size: 20px;
		line-height: 23px;
		color: white;
		font-weight: bold;
		transition: 0.3s;
		border-radius: 8px;

		&:hover {
			cursor: pointer;
			background: linear-gradient(0deg, #32374E -160.52%, #32374E 420.88%)!important;
		}
	}
}

@media screen and (min-width: 1440px) {
	#{$block} {
		&__list {
			--amount: 7;
			--counter: 1;
		}

		&__item {
			@include grid-item(7);
		}
	}
}

@media screen and (min-width: 1120px) and (max-width: 1439px) {
	#{$block} {
		&__list {
			--amount: 7;
			--counter: 1;
		}

		&__item {
			@include grid-item(7);
		}
	}
}

@media screen and (min-width: 840px) and (max-width: 1119px) {
	#{$block} {
		&__list {
			--amount: 3;
			--counter: 1;
			grid-gap: 1.5rem 3rem;
		}

		&__item {
			@include grid-item(3);
		}
	}
}

@media screen and (min-width: 480px) and (max-width: 839px) {
	#{$block} {
		&__list {
			--amount: 2;
			--counter: 1;
			grid-gap: 1.5rem 3rem;
		}

		&__item {
			@include grid-item(3);
		}
	}
}

@media screen and (max-width: 479px) {
	// #{$block} {
	// 	&__list {
	// 		--amount: 3;
	// 		grid-gap: 0rem 0rem;
	// 	}
	// 	&__item {
	// 		@include grid-item(3);
	// 	}
	// }
}

.blank {
	opacity: 0;
	display: none;
}

.honeycomb {
	.one {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -484.88%, #004C7F 100.16%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.two, .eight {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -393.76%, #004C7F 174.74%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.three {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -242.16%, #004C7F 344.1%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.four, .nine {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -242.16%, #004C7F 344.1%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.five {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.seven {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.six, .ten {
		.hex-grid__content {
			background: linear-gradient(270deg, #16E7CF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
		}
	}

	.selected .hex-grid__content {
		background: linear-gradient(0deg, #131936 -160.52%, #131936 420.88%)!important;
	}

	.text_first {
		color: #004C7F;
	}

	.text_second {
		color: #009BC8;
	}

	.vision_line {
		display: block;
		background: linear-gradient(90deg, #004C7F 0%, #16E7CF 99.47%);
		border-radius: 4px;
		height: 5px;
		width: 100%;
	}

	h5 {
		color: #000;
		font-weight: bold;
		margin-bottom: 20px;
	}

	p {
		color: black;
	}

	.Disabled {
		pointer-events: none!important;
		.hex-grid__content {
			background: linear-gradient(90deg, #D8D8D8 0%, #D8D8D8 99.47%)!important;
			&:hover {
				pointer-events: none!important;
				cursor: not-allowed;
				background: linear-gradient(90deg, #D8D8D8 0%, #D8D8D8 99.47%)!important;
			}
		}
	}

	.hidden {
		display: none;
	}
}

@media (max-width:667px) {
	.hex-grid__content {
		position: absolute;
		height: 100%;
		width: 90%;
		-webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
		clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 25%;
		text-decoration: none;
		text-align: center;
		font-size: 13px;
		line-height: 17px;
		color: white;
		font-weight: bold;
		transition: 0.3s;
		border-radius: 8px;
	}

	.hex-grid__list {
		--amount: 7;
		position: relative;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: block;
		grid-template-columns: unset;
		grid-gap: unset;
	}

	.hex-grid__item {
		position: relative;
		grid-column: unset;
		grid-row: unset;
		filter: drop-shadow(0 0 10px rgba(68, 68, 68, 0.08));
		height: 0;
		padding-bottom: 100px;
		display: inline-block;
		width: 32%;
		height: 40px;
	}

	li.hex-grid__item.blank {
		display: none;
	}
}


.vp_mobile {

	.card-header {
		padding: 0.75rem 1.25rem;
		margin-bottom: 0;
		background-color: rgba(51, 51, 51, 0.03);
		border-bottom: none;

		h5 .btn {
			color: white;
			font-weight: bold;
		}
	}

	#headingOne {
		background: linear-gradient(270deg, #16E7CF -484.88%, #004C7F 100.16%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingTwo, #headingThree {
		background: linear-gradient(270deg, #16E7CF -393.76%, #004C7F 174.74%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingFour {
		background: linear-gradient(270deg, #16E7CF -242.16%, #004C7F 344.1%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingFive, #headingSix {
		background: linear-gradient(2700deg, #16E7CF -160.52%, #004C7F 420.88%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingSeven {
		background: linear-gradient(2700deg, #16E7CF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingEight {
			background: linear-gradient(270deg, #16E7CF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	#headingNine, #headingTen {
		background: linear-gradient(2700deg, #0AFBDF -79.61%, #004C7F 503.73%);
			box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.08);
			border-radius: 4px;
	}

	.accordion-button {
		border: none;
		background: transparent;
		color: white;
		font-weight: 700;
		border-radius: 0;
	}

	.accordion-item:first-of-type .accordion-button {
		background: transparent;
		color: white;
	}

	.accordion-button::after {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
	 }

	 .accordion-item {
		border: none;
	}

}

div#accordion {
    margin-bottom: 4rem;
}


.vp_hero {
	padding-top: 14rem;
	padding-bottom: 10rem;
} 

.honeycomb {
	margin-top: 6rem;
}

.content_boxes {
	margin-top: 6rem;
	margin-bottom: 10rem;
	.content_box {
		h5 {
			color: #131936;
			font-size: 20px;
		}

		p {
			color: #4A516B;
			font-size: 16px;
			line-height: 24px;
		}
	}
}

img.vp_lines {
    bottom: -30px;
    position: absolute;
    left: 0;
}

.vp_contact {
	background: #F6F6F6;
	padding-bottom: 6rem;
}