.page_hero_standard {
	padding-top: 14rem;
    padding-bottom: 12rem;
}
.resource_links {
	margin-bottom: 6rem;
}
.resource_item {
	margin-top: -6rem;

	.wrapper {
		background: white;
		padding: 3rem;
		border-radius: 4px;
		height: 100%;

		p {
			min-height: 100px;
		}
		

		img {
			max-height: 100px;
		}
	}
}


.alliance_hero {
	padding-top: 22rem;
    padding-bottom: 12rem;
    position: relative;
}

.alliance_blocks {
	.item {
		position: relative!important;
		max-height: unset!important;
    min-height: 320px;
	}
}