@media (min-width:667px) {
    .hide_desktop {
        display: none!important;
    }
}

.pa_blocks_mobile .item p {
    font-size: 12px;
    line-height: 24px;
}

@media (min-width: 992px) {

    .vector_banner_item_mobile {
        display: none;
    }

}


@media (max-width: 992px) {

    .alliance_hero {
        background: none!important;
        background-color: #131935!important;

        padding-top: 10rem;
    padding-bottom: 10rem;
    }

    .gdc.hero_standard {
        padding-top: 11rem;
        padding-bottom: 7rem;
    }

    img.img-fulid.percentage_image {
        max-width: 160px;
        margin-left: 34px;
        margin-bottom: 30px;
    }
    
    .swiper-home {

        // .img_vector {
        //     display: none;
        // }
        
        .swiper-slide {
            background: $primary!important;
            padding-left: 30px;
            padding-right: 30px;
        }
        
        h2.xl {
            font-size: 28px;
            line-height: 36px;
        }
        
        h4 {
            font-size: 15px;
            line-height: 28px;
        }
        
        h5 {
            font-size: 12px;
    line-height: 28px;
    letter-spacing: 4px;
        }
        .swiper-button-next, .swiper-home .swiper-rtl .swiper-button-prev {
            right: 3%;
            left: auto;
        }
        .swiper-button-prev, .swiper-home .swiper-rtl .swiper-button-next {
            left: 3%;
            right: auto;
        }
        
        .slide_content_wrapper {
            margin-top: 130px;
            text-align: center;
        }

        .swiper-button-next, .swiper-button-prev {
            top: 55%;
        }

        .swiper-button-next:after, .swiper-button-prev:after {
            font-size: 19px;
        }
        
        .swiper-home .swiper-pagination {
            text-align: center;
        }
        
        .swiper-pagination {
           bottom: 110px;
        }

        .btn_primary {
            font-weight: 700;
            font-size: 14px;
            border-radius: 8px;
            padding: 16px 26px;
            display: inline;
            text-decoration: none;
            transition: 0.4s;
            border: none;
        }
        
        .vector_banner_item_mobile {
            position: absolute;
            max-width: 200px;
            margin: auto;
            text-align: center;
            left: 0;
            right: 0;
            top: 18%;
        }
        .img_vector {
            margin-top: -210px;
        }

        .vector_banner_item {
           display: none;
        }
    }
    
    
    .hero_banner .text_block {
        padding-top: 12rem;
    }
    
    #about-us .swiper-slide {
        text-align: center;
    }
    
    .swiper-testimonials .swiper-pagination {
        bottom: 10px !important;
        text-align: center!important;
    }
    
    .mx-lg-start {
        margin-left: 0!important;
    }
    
    .busyseason_header .accent_image {
        display: none!important;
    }
    
    img.busysceen {
        display: none;
    }
    
    .busyseason_works .box_content {
        min-height: auto!important;
        padding-bottom: 20px!important;
        border-radius: 8px!important;
    }
    
    .busyseason_works .busysceen {
        display: none;
    }
    
    .find_section .number {
        font-size: 26vw;
        color: white;
        font-weight: bold;
        position: absolute;
        top: 0vw;
        left: 8%;
    }
    
    .result_item .name {
        display: block;
    }
    
    .result_item .rating {
        font-size: 14px !important;
        color: #004C7F;
        margin-left: 0;
        margin-bottom: 20px;
    }
    
    .result_item .learn_more_link {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .busyseason_works {
        padding-top: 6rem;
        position: relative;
    }
    .banner_wrapper img {
        position: absolute;
        left: -2rem;
        display: none;
    }
    
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .row.g-0.comparison p {
        font-size: 12px;
    }
    
    #impact {
        .container {
            padding-left: 30px;
            padding-right: 30px;
        }
        
        .divider_gradient {
            margin-left: 0;
        }
        
        .margin_auto {
            margin: auto;
        }
    }
    
    .why_edu {
        background: $primary!important;
        padding-top: 7rem;
    }
    
    .books_graphic {
        position: absolute;
        top: -5rem;
        left: -7rem;
        right: auto;
        margin: auto;
        max-width: 240px;
        
        display: none;
    }
    
    .add_thingy_magik {
        &:before {
            display: none;
        }
    }
    
    .impact_header {
        padding-top: 17rem;
        padding-bottom: 13rem;
    }
    
    .impact_section_two .impact_accent_image {
        position: absolute;
        right: 0;
        top: -15rem;
        max-width: 100vw;
    }
    
    .hide_mobile {
        display: none;
    }
    .c_block .item_wrapper_careers {
        text-align: center;
    }
    .c_block:hover .hover_show {
        opacity: 1;
        padding: 50px;
    }
    .home_ca_section {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    
    .app_points .hs_item img {
        margin-bottom: 0;
        max-height: 100px;
    }
    
    .blog_image_wrapper {
        height: 180px;
    }
    
    .about_section .item {
        margin-left: 4rem;
        margin-bottom: 5rem;
    }
    
    .about_section .scrolling_section {
        border-left: 2px solid #16E7CF;
        padding-top: 0rem;
    }
    
    .about_section .item:before {
        content: "";
        width: 2rem;
        position: absolute;
        left: -4rem;
        top: 20px;
        height: 2px;
        background-color: #16E7CF;
        display: block;
    }
    
    #blog_post_calc .blog_image {
        position: absolute;
        width: 90%;
        margin-top: -130px;
    }
    
    .unset_minHeight {
        min-height: auto!important;
    }
    
    .why_edu img.impact_image {
        display: none;
    }
    
    .why_edu {
        padding-bottom: 7rem;
    }
    
    .hwp {
        padding-top: 7rem;
    }
    
    .applicant_signin {
        position: relative;
        margin-top: 3rem;
    }
    
    img.lines_left, img.lines_right {
        display: none;
    }
    
    .hp_wrapper {
        margin-top: 3rem;
        z-index: 99;
    }
    
    .resource_item {
        margin-top: 0;
        margin-bottom: 3rem;
    }
    
    img.vp_lines {
        display: none;
    }
    
    section.vp_contact {
        padding-top: 4rem;
    }
    
    .webinar_content li {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }
    
    .webinar_content ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }
    
    #clockdiv .time_item span {
        font-size: 38px;
        font-weight: 700;
        color: white;
    }
    
    .star_image {
        max-width: 160px;
    }
    
    #clockdiv .time_item {
        border-left: 1px solid #16E7CF;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    // .target_bb_mobile {
        //     background-position-x: 530px!important;
        // }
        
        .webinar_lines {
            display: none;
        }
        
        .practice_area_hero .pa_header {
            display: none;
        }
        
        .practice_area_hero {
            height: auto;
            position: relative;
            padding-top: 5rem;
            padding-bottom: 7rem;
        }
        
        .background-palebluegreymobile {
            background: $palebluegrey;
        }
        
        .background-whitemobile {
            background: white;
        }
        
        .pa_contact .contact_form_wrapper {
            padding: 2rem!important;
            button.btn_primary.btn_blue.d-block {
                margin-top: 20px;
            }
        }
        
        a.nav-item.nav-link.active:after {
            width: 40%!important;
            left: 0!important;
            margin: auto;
            right: 0;
        }
        
        .pa_wrapper .btn_primary {
            opacity: 1!important;
        }
        
        .hero_banner {
            height: auto;
            position: relative;
            padding-bottom: 10rem;
        }
        
        .pa_blocks .swiper-button-next:after, .pa_blocks .swiper-button-prev:after {
            font-family: swiper-icons;
            font-size: var(--swiper-navigation-size);
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
            color: white;
            font-size: 20px;
        }
        
        div.dropdown-multicol2 {
            max-width: 90%!important;
            width: 100%!important;
            margin: auto;
        }
        
        .navbar .nav-item {
            font-size: 14px;
            color: white;
            margin-right: 0!important;
        }
        
        .dropdown-menu.show {
            width: 90%;
            margin: auto;
            text-align: center;
            .dropdown-col {
                width: 100%;
                margin-top: 12px;
                
                .menu_heading {
                    margin-bottom: -2px;
                }
            }
        }
        
        #navbarDropdown {
            text-decoration: none!important;
            span.ml-1 {
                font-size: 14px;
                color: white;
                text-decoration: none!important;
            }
        }
        
        // 
        
        .navbar .nav-item {
            width: 49%;
            text-align: left;
            padding-left: 20px;
            display: inline-block;
            height: 62px;
            font-size: 15px;
            font-weight: 700;
            font-family: 'Inter', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        
        .navbar-nav {
            display: inline-block;
        }
        
        a.nav-item.nav-link.active {
            color: $turquoise!important;
        }
        
        a.nav-item.nav-link.active:after {
            display: none!important;
        }
        
        li.dropdown.font-weight-bold.web-avatar {
            list-style: none;
            display: inline-block;
            text-align: left;
            padding-left: 20px;
        }
        
        #navbarDropdown span.ml-1 {
            font-size: 12px;
            font-weight: 400;
            color: #0d4269;
            text-decoration: none !important;
        }
        
        .dropdown-menu.show {
            width: 90%;
            margin: auto;
            text-align: center;
            position: absolute;
            text-align: left;
            display: block!important;
        }
        
        // .dropdown-item {
            //     white-space: break-spaces;
            // }
            
            .contact_form .contact_form_wrapper {
                padding: 2rem;
            }
            
            .bg_intial {
                background-attachment: initial!important;
            }
            
            .box_item {
                border: none!important;
            }
            
            .targetSignIn {
                float: left;
            }
            
            .hideMobile {
                display: none;
            }
            
            .navbar-collapse {
                background: $primary!important;
                border: none!important;
            }
            
            a#navbarDropdownExpand {
                display: none!important;
            }
            
            .contact_form {
                position: relative;
                top: -16vh;
            }
            
            .contact_buttons {
                margin-top: -4rem;
                text-align: center;
            }
            
            #movement {
                height: auto;
                padding-bottom: 4rem;
                position: relative;
            }
            
            #updates {
                margin-top: 8rem;
                padding-bottom: 12rem;
            }
            
            .practice_areas_mobile .swiper-slide .number {
                left: 40px;
            }
            
            .swiper-pagination-bullet {
                width: 25px;
                height: 6px;
                display: inline-block;
                border-radius: 5px;
                background: #13384D;
                opacity: 1;
            }
            
            .tac_mobile {
                text-align: center;
            }
            
            .joindropdown {
                .dropdown-item {
                    white-space: normal;
                }
            }
            
            .about_hero {
                // height: 60vh;
                
                h1.xl {
                    padding-bottom: 3rem;
                }
            }
            
            .hidemenow {
                display: none!important;
            }
            
            .quick_links .item {
                background: #004C7F;
                border-radius: 8px;
                padding: 2rem;
            }
            
            .background_accent {
                display: none;
            }
            
            .why_points_overlap {
                position: relative;
            }
            
            .points_background {
                display: none;
            }
            
            .case_study {
                margin-top: 13vh;
                position: relative;
            }
            
            .casestudy_background {
                display: none;
            }
            
            .reasons {
                padding-top: 0;
                padding-top: 5rem;
                
                .col-12.col-md-3.text-center.align-self-end {
                    margin-bottom: 100px;
                }
            }
            
            .savings_calc_header {
                height: auto;
                padding: 12rem 0;
            }
            .calc_footer .text_wrapper {
                border-radius: 16px;
                padding-left: 3rem !important;
                position: relative;
            }
            
            .calc_footer_image {
                display: none!important;
            }
            
            .calc_part_one {
                max-width: 100%;
                position: relative;
                margin-top: 6rem;
            }
            
            .savings_info .savings_item {
                min-height: auto;
                margin-bottom: 6rem;
                h2.text-palebluegrey.xl.fw-700 {
                    margin-bottom: 2rem;
                }
            }
            .savings_info .savings_item:last-of-type {
                margin-bottom: 0;
            }
            
            // body#savings-calculator #updates {
                //     margin-top: 0;
                // }
                
                img.savingsgraphic {
                    display: none;
                }
                .values_wrapper {
                    margin-top: 9rem;
                }
                .calc_header {
                    padding-top: 12rem;
                }
                
            }
            
            @media (max-width: 992px) {
                
                .container-fluid.IMD_section.mb-5.mb-lg-0 {
                    padding-left: 0!important;
                    padding-right: 0!important; 
                }
                
                .IMD_section .idm_block h2 {
                    font-size: 130px;
                    font-weight: bold;
                    position: absolute;
                    top: 18%;
                    left: -35px;
                }
                
                .IMD_section .idm_block {
                    padding: 8%;
                    padding-top: 10rem;
                    position: relative;
                    overflow: hidden;
                }
                
                .vimeo_wrapper {
                    width: 100%;
                    max-width: 100%;
                    left: 0px;
                    
                    iframe {
                        width: 100%;
                    }
                }
                
                .results_main_wrapper {
                    .container-fluid {
                        padding-left: 0!important;
                        padding-right: 0!important; 
                    }
                    
                    .results_wrapper {
                        padding-left: 40px;
                        padding-right: 40px;
                    }
                }
                .line_float_pa {
                    display: none;
                }
                
                .hs_image_bb {
                    display: none!important;
                }
                
                .result_item .box_tags .item {
                    margin-right: 5px;
                    margin-bottom: 8px;
                }
                
                .banner_wrapper h4 {
                    max-width: 80%;
                }
                
                .busyseason_header {
                    padding-top: 8rem;
                    padding-bottom: 4rem;
                    position: relative;
                    margin-top: 2rem;
                }
                
                .box_content h2 {
                    font-size: 44px;
                }
                
                .busyseason_works {
                    padding-top: 13rem;
                    position: relative;
                }
                .result_item .name {
                    display: flex;
                    line-height: normal;
                }
                
                .result_item .text_small {
                    font-size: 12px;
                    color: #797979;
                    margin-bottom: 30px;
                    line-height: normal;
                    padding-top: 0;
                }
            }
            
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1190px) 
            and (-webkit-min-device-pixel-ratio: 1) {
                
                .mt-lg {
                    margin-top: 1rem;
                }
                
                .fimd_sect {
                    .divider_gradient {
                        margin-left: 0;
                    }
                }
                
                .fimd_sect .fimd_rocket {
                    display: none;
                }
                
                .fimd_sect {
                    padding-bottom: 1rem;
                }
                
                .fimd_testimonals {
                    padding-top: 5rem;
                    padding-bottom: 5rem;
                    
                    .divider_gradient {
                        margin-left: 0;
                    }
                }
                
                .busy_season_footer .message {
                    position: relative;
                }
                
                
                .busyseason_header .accent_image {
                    width: 100%;
                    position: absolute;
                    left: -90px;
                    max-width: -moz-fit-content;
                    max-width: fit-content;
                    top: 0;
                    display: block;
                    max-width: 490px;
                    height: 100%;
                }
                
                .books_graphic {
                    left: -7rem;
                    right: auto;
                }
                
                
                .why_edu {
                    padding-bottom: 7rem;
                }
                
                .hwp {
                    padding-top: 7rem;
                }
                
                .why_edu img.impact_image {
                    // position: absolute;
                    // right: 0;
                    // bottom: -22%;
                    // max-width: 60%;
                    
                    display: none;
                }
                
                .hp_wrapper {
                    margin-top: 3rem;
                    z-index: 99;
                    margin-bottom: 9rem;
                }
                
                .ar_block {
                    height: 100%;
                }
                
                
                .webinar_content li {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }
                
                .webinar_content ul {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }
                
                #blog_post_calc {
                    .blog_image {
                        position: relative;
                        width: 100%;
                        bottom: -6.7rem;
                    }
                }
                
                #clockdiv .time_item {
                    border-left: 1px solid #16E7CF;
                    padding-right: 10px;
                    padding-left: 10px;
                }
                
                .about_hero .header_lines {
                    display: none!important;
                }
                
                .calc_part_one {
                    max-width: 100%;
                    position: relative;
                    margin-top: -7rem;
                }
                
                .hero_banner {
                    height: 100vh;
                    position: relative;
                    background-attachment: initial!important;
                }
                
                .pa_blocks_large .item:hover {
                    max-height: 470px;
                    cursor: pointer;
                    background: white !important;
                }
                .pa_blocks_large .item {
                    position: absolute;
                    overflow: hidden;
                    z-index: 9999;
                    padding-top: 7rem !important;
                    border-radius: 8px;
                    min-height: 410px;
                    transition: 0.4s;
                    padding: 30px!important;
                    padding-top: 7rem !important;
                }
                
                #updates_engage img.background_image {
                    display: none;
                }
                #updates_engage .row {
                    height: 50vh;
                }
                .vimeo_wrapper {
                    margin-top: -6rem;
                }
                
                .hs_image_bb {
                    display: none;
                }
                
                .savings_calc_header {
                    height: auto;
                    padding: 12rem 0;
                }
                .calc_footer .text_wrapper {
                    border-radius: 16px;
                    padding-left: 3rem !important;
                    position: relative;
                }
                
                .case_study {
                    margin-top: 8vh;
                    padding-bottom: 10rem;
                    position: absolute;
                    width: 100%;
                }
                .casestudy_background {
                    background: #f2f2f2;
                    height: 70vh;
                }
                
                
            }
            
            .ar_block {
                height: 100%;
            }
            
            
            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1190px) 
            and (-webkit-min-device-pixel-ratio: 2) {
                a.btn_primary.btn_orange.d-inline-block.d-lg-inline.mb-5.mb-lg-0 {
                    margin-bottom: 0!important;
                }
                
                .banner_wrapper {
                    padding: 4rem;
                }
                
                .find_section {
                    padding-top: 20rem;
                }
                
                img.busysceen {
                    position: absolute;
                    right: 0vw;
                    top: -10px;
                    z-index: 9999;
                }
                
                
                .contact_hero {
                    padding-top: 18vh;
                    padding-bottom: 28vh;
                }
                
                
                .hp_wrapper img {
                    max-width: 100%;
                }
                
                .impact_block p {
                    min-height: 155px;
                }
                
                img.vp_lines {
                    bottom: -200px;
                    position: absolute;
                    left: 0;
                }
                
                .featured_post {
                    margin-top: 2rem;
                }
                
                .ar_block {
                    height: 100%;
                }
                
                .webinar_content li {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }
                
                .webinar_content ul {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }
                
                .about_section .item {
                    min-height: 40vh;
                    margin-left: 8rem;
                    position: relative;
                }
                
                
                .why_points.hs {
                    min-height: 120vh;
                    padding-top: 20vh;
                }
                
                .points_background {
                    background: #131935;
                    margin-top: -2px;
                }
                
                .case_study {
                    margin-top: 8vh;
                    padding-bottom: 10rem;
                    position: relative;
                    width: 100%;
                }
                .casestudy_background {
                    background: #f2f2f2;
                    height: 70vh;
                    display: none;
                }
                
                .hero_banner {
                    height: 100vh;
                    position: relative;
                    background-attachment: initial!important;
                }
                
                .pa_blocks_large .item:hover {
                    max-height: 470px;
                    cursor: pointer;
                    background: white !important;
                }
                .pa_blocks_large .item {
                    position: absolute;
                    overflow: hidden;
                    z-index: 9999;
                    padding-top: 7rem !important;
                    border-radius: 8px;
                    min-height: 410px;
                    transition: 0.4s;
                    padding: 30px!important;
                    padding-top: 7rem !important;
                }
                
                #updates_engage img.background_image {
                    display: none;
                }
                #updates_engage .row {
                    height: 50vh;
                }
                .vimeo_wrapper {
                    margin-top: 4rem;
                }
                
                #engage .container {
                    max-width: 95%;
                }
                
                .targetVP_ipadP {
                    margin-bottom: 30px;
                }
                
                .hs_image_bb {
                    display: none;
                }
                
                .savings_calc_header {
                    height: auto;
                    padding: 12rem 0;
                }
                .calc_footer .text_wrapper {
                    border-radius: 16px;
                    padding-left: 3rem !important;
                    position: relative;
                }
                
                .calc_footer_image {
                    display: none!important;
                }
                
                .calc_part_one {
                    max-width: 100%;
                    position: relative;
                    margin-top: 6rem;
                }
                
                .savings_info .savings_item {
                    min-height: auto;
                    margin-bottom: 6rem;
                    h2.text-palebluegrey.xl.fw-700 {
                        margin-bottom: 2rem;
                    }
                }
                .savings_info .savings_item:last-of-type {
                    margin-bottom: 0;
                }
                
                body#savings-calculator #updates {
                    margin-top: 4rem;
                }
                
                img.savingsgraphic {
                    display: none;
                }
                .values_wrapper {
                    margin-top: 9rem;
                }
                
            }
            
            @media (max-width: 768px) {
                .contact_buttons {
                    margin-top: 6rem;
                    text-align: center;
                }
                
                img.graphic_one {
                    margin-top: 0rem;
                    margin-bottom: -3rem;
                    max-width: 100%;
                    z-index: 9999;
                    position: relative;
                }
                
                li.dropdown.font-weight-bold.web-avatar {
                    list-style: none;
                    display: block;
                    text-align: left;
                    padding-left: 20px;
                    margin-top: 20px;
                }
                
                #navbarDropdown span.ml-1 {
                    font-size: 16px;
                    font-weight: 700;
                    color: white!important;
                    text-decoration: none !important;
                }
            }
            
            section#points .col-12 {
                margin-bottom: 30px;
            }
            
            @media (max-width: 480px) {
                .target_contact_content {
                    margin-top: -5rem;
                    z-index: 9999;
                    position: relative;
                }
            }
            
            .swiper-pagination-bullet-active {
                opacity: 1;
                background: #16E7CF!important;
            }
            
            .hs_image_bb {
                display: none;
            }
            
            @media (hover: none) {
                a:hover { color: inherit; }
            }
            
            @media (max-width: 553px) and (min-width: 423px) {
                .carousel-slider {
                    max-width: 90%!important;
                }
                
                .practice_areas_mobile .swiper-slide {
                    width: 100%!important;
                }
            }
            
            @media only screen 
            and (min-device-width : 820px) 
            and (max-device-width : 1180px) 
            and (orientation : landscape) {
                
                .busy_season_footer .message {
                    display: block;
                    max-width: 470px;
                    right: -120px;
                }
                
                .mt-lg {
                    margin-top: 4rem;
                }
                
                .th3bs h3 {
                    font-size: 28px;
                    line-height: 46px;
                }
                
                .find_section {
                    padding-top: 8rem;
                }
                
                a.btn_primary.btn_orange.d-inline-block.d-lg-inline.mb-5.mb-lg-0 {
                    margin-bottom: 0!important;
                }
                
                .find_section .number {
                    font-size: 26vw;
                    color: white;
                    font-weight: bold;
                    position: absolute;
                    top: -10%;
                    left: -5%;
                }
                
                .books_graphic {
                    left: 0;
                    right: 0;
                }
                
                .tas_contact {
                    margin-top: 0rem!important;
                    z-index: 99;
                    position: relative;
                }
                
                .tas_hero {
                    background: #131935 !important;
                }
                
                .contactsection_text {
                    padding-top: 4rem!important;
                    padding-left: 50px;
                }
                
            }