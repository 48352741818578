.divider_accent {
	width: 35px;
	height: 3px;
	display: block;
	background-color: #00B5D9;

}

.divider_turquoise {
	width: 100px;
	height: 3px;
	display: block;
	background-color: #16E7CF;

}

.divider_gradient {
	background: linear-gradient(90deg, #16E7CF -21.81%, #009BC8 100%);
	width: 100px;
	height: 3px;
	display: block;
	border-radius: 4px;

	@media (max-width: 992px) {
		margin: auto;
	}
}

.divider_grey {
	height: 2px;
	width: 100%;
	display: block;
	background-color: #D6D5D5;

}