.savings_calc_header {
	height: 100vh;
	
	@media (max-width: 768px) {
		height: 100vh;
		padding-top: 8rem;
	}
	
	.image {
		position: absolute;
		right: 0;
		bottom: 0;
		
		@media (max-width: 768px) {
			width: 80%;
			margin: auto;
			left: 0;
		}
	}
}

.calculator_wrapper {
	padding: 3rem;
	padding-bottom: 3rem;
	border-radius: 16px;
	
	.input-group-text {
		border-radius: 4px 0 0 4px;
		border-right: 0;
		height: 50px;
	}
	
	.form-control {
		height: 50px;
	}
	
	.target_width {
		width: 80%;
	}
}

.text_ms {
	font-size: 14px;
}

// img {
// 	max-width: 80px;
// 	margin-left: 6px;
// 	margin-top: -2px;
// }

.recommended_bar {
	height: 8px;
	background: linear-gradient(332.78deg, #2CE9D8 -1.7%, #009BC8 100.93%);
	border-radius: 4px;
	position: absolute;
	z-index: 10;
	transition: 0.7s;
	right: 0;
}


.gradient_text {
	background: linear-gradient(332.78deg, #2CE9D8 -1.7%, #009BC8 100.93%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.graph_guide {
	background: #131936;
	display: block;
	background: #131936;
	border-radius: 4px;
	width: 133px;
	padding: 8px;
	position: absolute;
	right: 0;
	z-index: 99;
	bottom: 0;
	transition: 0.5s;
	height: 67px;
	padding-top: 5px;
	margin-bottom: -30px;
	
	&:before {
		content: "";
		display: block;
		background: #131936;
		width: 10px;
		height: 14px;
		-webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
		clip-path: polygon(100% 0, 0 50%, 100% 100%);
		position: absolute;
		right: 133px;
		top: 30px;
	}
	
	&:after {
		content: "";
		width: 430px;
		height: 1px;
		background: #131936;
		display: block;
		position: absolute;
		right: 0;
		top: 37px;
		z-index: 10;
	}
	
	p {
		font-size: 12px;
		margin-bottom: 0;
		line-height: 16px;
		position: relative;
		z-index: 99;
	}
	
	img {
		width: 70px;
		padding-bottom: 5px;
	}
}


@media (max-width: 768px) {
	.graph_guide {
		background: #131936;
		display: block;
		background: #131936;
		border-radius: 4px;
		width: 92px;
		padding: 8px;
		position: absolute;
		right: 0;
		z-index: 99;
		bottom: 0;
		transition: 0.5s;
		height: 88px;
		padding-top: 5px;
		margin-bottom: -50px;
	}
	
	.graph_guide p {
		font-size: 10px;
		margin-bottom: 0;
		line-height: 16px;
		position: relative;
		z-index: 99;
	}
	
	.graph_guide:before {
		content: "";
		display: block;
		background: #131936;
		width: 10px;
		height: 14px;
		-webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
		clip-path: polygon(100% 0, 0 50%, 100% 100%);
		position: absolute;
		right: 91px;
		top: 30px;
	}
	
	.graph_guide:after {
		content: "";
		width: 188px;
		height: 1px;
		background: #131936;
		display: block;
		position: absolute;
		right: 0;
		top: 37px;
		z-index: 10;
	}
	
	.graph_wrapper .value_indicator {
		font-size: 11px;
		color: #9093A1;
		position: absolute;
		left: 5px;
		margin-bottom: -22px;
		width: 80px;
	}
	
	// .graph_wrapper .value_indicator:before {
	// 	content: "";
	// 	display: block;
	// 	width: 30px;
	// 	border: 1px dashed #9093A1;
	// 	position: absolute;
	// 	left: 74px;
	// 	top: 9px;
		
	// }
	
	.graph_wrapper {
		position: relative;
		border-bottom: none;
		overflow: visible;
		background: #f2f2f2;
		/* padding: 30px; */
		border-radius: 10px;
		padding-top: 30px;
	}
}

.calc_wrapper {
	// height: 60vh;
	
	@media (max-width: 768px) {
		height: auto;
	}
}

.calc_footer {
	// margin-top: 40vh;
	position: relative;
	
	@media (max-width: 768px) {
		margin-top: 3rem;
	}
	
	img.header_lines {
		position: absolute;
		right: 0;
		top: -130px;
		z-index: 9990;
	}
	
	.text_wrapper {
		border-radius: 16px;
		padding-left: 13rem!important;
		position: relative;
		@media (max-width: 768px) {
			padding-left: 3rem!important;
		}
	}
}

.calc_footer_image {
	position: absolute;
	left: -140px;
	top: -30px;
}

#blog_post_calc {

	margin-top: 4rem;
	.blog_image {
		position: absolute;
		width: 90%;
		margin-top: -40px;
	}
}