#header_wrap {
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 99999;
    top: 0;
    transition: top 0.2s ease-in-out;
    
    .header {
        transition: top 0.3s;
    }
    
}

.logo_img {
    background-image: url('/images_v3/logo_white.png');
    width: 96px;
    height: 21px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
}

.pre_nav {
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    
    p, a {
        margin-bottom: 0!important;
    }
}

.expander_menu {
    position: absolute;
    width: 100vw;
    z-index: 9999;
    left: 0;
    top: -500px;
    transition: 0.4s;
    z-index: 999;
    
    .expand_titles {
        color: white;
    }

    h5 {
        font-size: 18px;
    }
    
    .img_col {
        background: #004C7F;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        padding: 20px;
        
        img {
            position: absolute;
            max-width: 190px;
            right: 0;
            bottom: 0;
        }
        
        img.this {
            position: absolute;
            max-width: 250px;
            right: 0;
            bottom: 0;
        }
        
    }
}

.expander_menu.showme {
    top: unset;
}

.header_dark .expander_menu {
    background: white;
}

.navbar {
    border-bottom: 1px solid white;
    padding-top: 16px;
    padding-bottom: 16px;
    
    .nav-item {
        font-size: 14px;
        color: white;
        margin-right: 12px;
    }
    
    .nav-link {
        color: white;
    }
    
    a#navbarDropdownExpand {
        color: white;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        border: 1px solid $turquoise;
        padding: 10px 20px;
        border-radius: 8px;
        transition: 0.4s;
        
        &:hover {
            background: $turquoise;
        }
    }
}

div.dropdown-multicol2{
    width: 420px;
}
div.dropdown-multicol2>div.dropdown-col{
    display:inline-block;
    width: 49%;
}

.dropdown-menu.show {
    display: block;
    border: none;
    padding: 15px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
}


.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #555555;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 8px;
    clear: both;
    font-weight: 400;
    color: #555555;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.main_nav {
    padding-bottom: 15px;
    padding-top: 15px;
}


.dropdown-menu.show {
    display: flex!important;
}

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }
    .navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
    .navbar .dropdown-menu.fade-up{ top:180%;  }
    .navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg);     padding-left: 20px; }
}

.dropdown .dropdown-item {
    font-size: 14px;
    line-height: 24px;
}

.menu_heading {
    color: #05A4CA!important;
    font-size: 11px!important;
    font-weight: bold!important;
    
    &:hover {
        background: transparent;
        cursor: default;
    }
}

.header_dark .header {
    background: #141936;
    position: relative;
    .navbar {
        border: none;
    }
}

.nav-up {
    top: -140px!important;
    transition: top 0.3s;
}

@media (max-width: 768px) {
    
    div#navbarNavAltMarkup {
        background: #141936;
        text-align: center;
        padding-bottom: 50px;
        padding-top: 30px;
    }
    
}

button.navbar-toggler {
    border: none;
}

#error-page,
#webinar,
#about-us,
#higher-standards,
#smarter-deployments,
#better-backups,
#single-post,
#savings-calculator-report,
#referrals,
#landing,
#its-not-too-late,
#top-employer,
#alliance {

    button.navbar-toggler svg {
        color: $primary!important;

        @media (max-width:992px) {
            color: white!important;
        }
    }
    #header_wrap {
        background: $white;
    }
    .navbar {
        border: none;
    }

    .expander_menu {
        background: $white;
    }

    .navbar .nav-link {
        color: $primary;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
    }

    .navbar a#navbarDropdownExpand {
        color: $primary;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        border: 1px solid #00E7CF;
        padding: 10px 20px;
        border-radius: 8px;
        transition: 0.4s;
    }

    .header_dark .header {
        background: white;
    }

    @media (min-width: 992px) {

        .logo_img {
            background-image: url('/images_v3/logo_dark.png');
            width: 96px;
            height: 21px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
        }

    }
    

    @media (max-width: 992px) {
        .navbar-collapse {
            background: white!important;
            border: none !important;
        }
    }
}


#pa-home,
#savings-calculator {
    #header_wrap {
        background: $primary;
    }
    .navbar {
        border: none;
    }

    .expander_menu {
        background: $primary;
    }

    .logo_img {
        background-image: url('/images_v3/logo_white.png');
        width: 96px;
        height: 21px;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

a.nav-item.nav-link.active {
    color: white;

    &:after {
        content: '';
        position: absolute;
        background: $turquoise;
        width: 30px;
        height: 2px;
        display: block;
        bottom: 0;
        width: 100%;
        left: 0;
    }
}

.main_nav {
    padding-bottom: 0px;
    padding-top: 0px;
}

.navbar .nav-link {
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.nav-avatar {
    display: inline-block;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background: #4de7cf;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    margin-right: 8px;
}

.nav-avatar {
    display: inline-block;
}

li.dropdown.font-weight-bold.web-avatar {
    list-style: none;
    display: inline-block;
}

.pre_nav a {
    color: #0d4269;
    display: inline-block;
    margin-left: 16px;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
}

.dropdown-menu .dropdown-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.main_nav {
    padding-bottom: 10px;
    padding-top: 10px;
}

#header_mobile {
    background: $primary;
    position: fixed;
    z-index: 99999;
    width: 100%;
    border: none;

    .main_nav {
        padding-bottom: 10px;
        padding-top: 10px;
        border: none;
    }

    .navbar .nav-item {
        width: 100%;
        text-align: left;
        padding-left: 20px;
        display: inline-block;
        height: 60px;
        font-size: 18px;
        font-weight: 700;
        font-family: "Inter", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a.nav-item.nav-link.hide_desktop.targetSignIn.btn-stroke {
        border: 2px solid #4de7cf;
        border-radius: 8px;
        text-align: center!important;
        padding-left: 0px!important;
        padding-top: 14px;
        margin-top: 20px;
    }

    .dropdown-menu.show .dropdown-col {
        width: 100%;
        margin-top: 12px;
        width: 49%;
    }
}