.calc_header {
	
	padding-top: 20rem;
	
	.first_wrapper {
		padding-bottom: 10rem;
	}
	
	
	
	.blue_wrapper {
		background: $primary;
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
	
	.savingsgraphic {
		position: absolute;
		z-index: 999999;
		left: -20px;
		width: 100%;
		max-width: fit-content;
	}
	
}

span.behind_line {
	background: #4de7cf;
    height: 8px;
    display: block;
    border-radius: 20px;
}

.loading_text {
	font-size: 12px;
}

.calc_part_one {
	background: white;
	border-radius: 16px;
	padding: 28px;
	max-width: 520px;
	width: 100%;
	position: absolute;
	right: 0;
	
	.btn_flag {
		border: 2px solid #E1E4EC;
		border-radius: 100px;
		padding: 6px 10px;
		background: white;
		transition: 0.3s;
		font-size: 12px;
		
		img {
			margin-right: 10px;
			margin-top: -5px;
			max-width: 20px;
		}
		
		&:hover {
			border: 2px solid #131936;
			
			box-shadow: 1px 10px 19px -8px rgba(0,0,0,0.3);
			-webkit-box-shadow: 1px 10px 19px -8px rgba(0,0,0,0.3);
			-moz-box-shadow: 1px 10px 19px -8px rgba(0,0,0,0.3);
		}
		
		&:focus, &:visited {
			border: 2px solid #131936;
			background: rgba(19, 25, 54, 0.08);
		}
	}
	
	.input-group-text {
		border-radius: 8px 0 0 8px;
		height: 50px;
	}
	
	.form-control {
		height: 50px;
		border-radius: 0 8px 8px 0;
	}
}


.values_wrapper {
	background: $primary;
	padding: 28px;
	border-radius: 16px;
}

.tooltippy {
	width: 200px;
	position: absolute;
	left: -89px;
	background: #E1E4EC;
	font-weight: 400;
	padding: 10px;
	border-radius: 8px;
	top: 40px;
	
	&::before {
		content:"";
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 15px solid #E1E4EC;
		position: absolute;
		top: -15px;
		left: 81px;
	}
}

.half_line {
	border-top: 1px dashed #E1E4EC;
	display: block;
	width: 100%;
	height: 2px;
	position: absolute;
	top: 50%;
}

.full_line {
	border-top: 1px dashed #E1E4EC;
	display: block;
	width: 100%;
	height: 2px;
	position: absolute;
}

.graph_wrapper {
	
	position: relative;
	border-bottom: 3px solid $primary;
	
	overflow: hidden;
	
	.graph {
		// width: 80px;
		height: 230px;
		// background: rgba(205, 211, 239, 0.5);
		// border-radius: 8px 8px 0 0;
		// overflow: hidden;
		
		@media (max-width: 768px) {
			margin: auto;
		}
		
		.filler {
			background: rgba(205, 211, 239, 0.5);
			width: 80px;
			position: absolute;
			bottom: 0;
			border-radius: 8px 8px 0 0;
		}
	}
	
	.value_indicator {
		font-size: 11px;
		color: #9093A1;
		position: absolute;
		left: 95px;
		margin-bottom: 0px;
		font-weight: 400;
		margin-bottom: -15px;
	}
}

.dragdealer {
	position: relative;
	height: 8px;
	background: #E1E4EC;
	border-radius: 4px;
}
.dragdealer .handle {
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
}
.dragdealer .red-bar {
	width: 24px;
	height: 24px;
	background: white;
	color: #FFF;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
	border: 6px solid $primary;
	border-radius: 50%;
	margin-top: -8px;
	z-index: 99;
	
	.slide_value {
		color: #4A516B;
		position: absolute;
		top: 31px;
		left: -8px;
		font-weight: 700;
	}
}
.dragdealer .disabled {
	background: #898989;
}

.indicator {
	position: absolute;
	top: -25px;
	margin-right: -10px;
	right: 0;
	transition: 0.7s;
	
	&:after {
		content: "";
		display: block;
		border-right: 1px dotted #2CE9D8;
		position: absolute;
		top: -40px;
		width: 20px;
		height: 40px;
		right: 0;
	}
}

.slide_value {
	opacity: 0;
}
.slide_value_show {
	opacity: 1;
}

.savings_info {

	h2.xl {
		font-size: 90px;
	}

	.savings_item {
		min-height: auto;
		margin-bottom: 8rem;

		&:last-of-type {
			min-height: fit-content;
			padding-bottom: 5rem;
			margin-bottom: 0rem;
		}
	}
	
}

#scrollerWaypoint {
	padding-bottom: 0!important;
	// margin-bottom: 10rem;
}

.stickthis {
    margin-top: -30px;
}