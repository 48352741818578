.error_wrapper {
	height: 72vh;
	overflow: hidden;
	position: relative;
	margin-top: 120px;
	
	@media (max-width: 667px) {
		height: 100vh;
		
		.col-12.col-md-5.offset-md-7.text-center.text-md-left {
			margin-top: -30vh;
			z-index: 99;
			position: relative;
		}
	}
}

.error_image {
	z-index: 9;
	position: absolute;
	top: 0;
	max-width: 35vw;
	
	@media (max-width: 667px) {
		position: absolute;
		max-width: 80vw;
		margin: auto;
		left: 0;
		right: 0;
		bottom: -70vh;
	}
}