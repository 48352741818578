.dp_pre_header {
    background: #F3F4F7;
    padding-top: 30px;
    padding-bottom: 6rem;
    
    .back_link {
        color: $primary;
        font-size: 16px;
        text-decoration: none;
        font-weight: bold;
    }
    
    p {
        margin-right: 20px;
    }
    
    .notice_orange {
        background: $orange;
        color: white;
        padding: 8px 24px;
        border-radius: 8px;
        margin-left: 20px;
    }
    
}

section.dp_header {
    margin-top: -4rem;
    transition: 1s;
}

// .sticky.dp_header {
    //     margin-top: 0;
    // }
    
    
    .feather-16{
        width: 16px;
        height: 16px;
    }
    
    .feather-24{
        width: 24px;
        height: 24px;
    }
    
    .feather-32{
        width: 32px;
        height: 32px;
    }
    
    .dp_header {
        
        .profile_wrapper {
            position: relative;
            display: block;
            width: 100%;
            max-width: 200px;
            max-height: 200px;
        }
        
        .name_wrapper {
            margin-left: 200px;
        }
        
        .rating_item {
            width: 40px;
            height: 40px;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: white;
            background: #3279C6;
            border-radius: 20px;
            display: block;
            z-index: 9999;
            position: absolute;
            right: 40px;
            padding-top: 8px;
            transition: 1s;
        }
        
        .rating_move {
            right: 80px;
        }
        
        .row {
            background: $primary;
            border-radius: 8px;
            padding: 30px;
            padding-bottom: 10px;
        }
        
        .profile_image {
            position: absolute;
            transition: 1s;
            border-radius: 50%;
            width: 165px;
            height: 165px;
            margin-top: 15px;
        }

        .profile-avatar {
            position: absolute;
            transition: 1s;
            border-radius: 50%;
            width: 165px;
            height: 165px;
            margin-top: 15px;
            line-height: 165px;
            background: #90b7e0;
            color: #fff;
            font-size: 50px;
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
        }
        
        .dp_quali_items {
            min-height: 30px;
            transition: 1s;

            .item {
                color: #333;
                font-size: 14px;
                padding: 4px 8px;
                border-radius: 4px;
                background: #E1E4EC;
                display: inline-block;
                margin-right: 8px;
                transition: 1s;
            }
        }
        
        @keyframes example {
            0%   {opacity: 0;}
            100% {opacity: 1;}
        }
        
        .action_icon {
            color: white;
            border: 1px solid #3279C6;
            width: 40px;
            height: 40px;
            padding-left: 10px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 6px;
            margin-right: 12px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            
            span {
                display: inline-block;
                position: absolute;
                left: 0;
                opacity: 0;
            }
            
            &:hover {
                background: #3279C6;
                width: 98px;
                padding-left: 10px;
                
                span {
                    left: 35px;
                    opacity: 1;
                }
            }
        }
        
        .action_icon_btn {
            
            font-size: 16px;
            font-weight: bold;
            background: #3279C6;
            padding: 6px 46px;
            display: inline-block;
            color: white;
            text-decoration: none;
            border-radius: 20px;
            
            color: white;
            width: 100px;
            height: 40px;
            padding-left: 30px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 7px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            
            span {
                display: inline-block;
                position: absolute;
                left: 100px;
                transition: 2s;
                opacity: 0;
                width: 200px;
                
            }
            
            &:hover {
                background: #3279C6;
                width: 190px;
                padding-left: 30px;
                
                span {
                    left: 73px;
                    opacity: 1;
                }
            }
        }

        .login_icon_btn {
            span {
                left: 77px !important;
            }
        }

        .im_interested_btn {
            
            font-size: 16px;
            font-weight: bold;
            background: #F28F16;
            padding: 6px 46px;
            display: inline-block;
            color: white;
            text-decoration: none;
            border-radius: 20px;
            
            color: white;
            width: 100px;
            height: 40px;
            padding-left: 30px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 7px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            
            span {
                display: inline-block;
                position: absolute;
                left: 100px;
                transition: 2s;
                opacity: 0;
                width: 200px;
                
            }
            
            &:hover {
                background: #F28F16;
                width: 190px;
                padding-left: 30px;
                
                span {
                    left: 73px;
                    opacity: 1;
                }
            }
        }


        .interview_button {
            color: white;
            height: 40px;
            padding-left: 10px;
            display: inline-block;
            padding-top: 6px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            text-decoration: none;
          
            
            &:hover {
                color: white;
            }
        }

        .action_icon_cal {
            color: white;
            height: 40px;
            display: inline-block;
            padding-top: 6px;
            margin-right: 12px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            text-decoration: none;
            
            &:hover {
                color: #f1f1f1;
                text-decoration:dotted;           
            }
        }

        .neg {
            color: white;
            border: 1px solid $red;
            width: 40px;
            height: 40px;
            padding-left: 10px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 6px;
            margin-right: 12px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            
            span {
                display: inline-block;
                position: absolute;
                left: 0;
                opacity: 0;
            }
            
            &:hover {
                background: $red;
                width: 98px;
                padding-left: 10px;
                
                span {
                    left: 35px;
                    opacity: 1;
                }
            }
        }

        .btn_booked {

            font-size: 16px;
            font-weight: bold;
            background: #00E7CF;
            padding: 6px 46px;
            display: inline-block;
            color: $primary;
            text-decoration: none;
            border-radius: 20px;
            
            width: 100px;
            height: 40px;
            padding-left: 20px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 7px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
       
        }


        .bell {
            color: white;
            border: 1px solid #F28F16;
            background: #F28F16;
            width: 40px;
            height: 40px;
            padding-left: 10px;
            border-radius: 40px;
            display: inline-block;
            padding-top: 6px;
            margin-right: 12px;
            transition: 0.8s;
            position: relative;
            text-align: left;
            overflow: hidden;
            
            span {
                display: inline-block;
                position: absolute;
                left: 0;
                opacity: 0;
            }
            
            &:hover {
                background: #F28F16;
                width: 288px;
                padding-left: 10px;
                font-size: 12px;
                padding-top: 10px;
                
                span {
                    left: 35px;
                    opacity: 1;
                }
            }
        }
        
        .views {
            font-size: 16px;
            min-height: 24px;
            color: white;
            transition: 1s;
        }
        
        h3 {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 15px;
            margin-top: 15px;
            transition: 1s;
        }
    }

    .alert p.sm {
        line-height: 18px;
    }
    
    .dp_about {
        margin-top: 6rem;
        
        .dp_tag {
            background: #3279C6;
            display: inline-block;
            color: white;
            padding: 8px 26px;
            border-radius: 6px;
            margin-right: 16px;
            
            .feather_icon {
                margin-top: -4px;
                margin-right: 10px;
            }
        }
        
        a {
            font-size: 14px;
            text-decoration: none;
        }
    }
    
    #dp_skills {
        
        margin-top: 3rem;
        
        .skill_block {
            border: 1px solid #DEDEDE;
            border-radius: 8px;
            padding: 2rem 3rem;
            
            .skills_wrapper {
                .skill_item {
                    display: inline-block;
                    border: 1px solid #3279C6;
                    padding: 6px 22px;
                    font-size: 14px;
                    color: #3279C6;
                    margin-right: 14px;
                    margin-bottom: 16px;
                    border-radius: 20px;
                }
            }
        }
        
        
        .industry_exp {
            background: #F3F4F7;
            border-radius: 8px;
            padding: 2rem 3rem;
            
            .bar_wrapper {
                
                .bar_item {
                    
                    margin-bottom: 20px;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                    
                    .bar {
                        
                        p {
                            margin-bottom: 0;
                            margin-left: 20px;
                            
                            span {
                                color: #797979;
                            }
                        }
                        
                        .bar_line {
                            height: 15px;
                            border-radius: 10px;
                            background: #3279C6;
                            display: inline-block;
                            width: 0;
                            transition: 2s;
                        }
                    }
                }
            }
        }
        
    }
    
    .qualifications {
        background: #F3F4F7;
        border-radius: 8px;
        padding: 2rem 3rem;
        border: 0!important;
    }
    
    .dp_divider_blue {
        height: 1px;
        display: block;
        width: 100%;
        background: #3279C6;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    
    #dp_experience {
        
        .work_wrapper {
            border: 1px solid #DEDEDE;
            padding: 3rem 2rem;
            border-radius: 8px;
            overflow: hidden;
            
            .exp_date {
                font-size: 14px;
                color: #3279C6;
            }
            
            .exp_header {
                padding-bottom: 20px;
                border-bottom: 1px solid #DEDEDE;
                margin-bottom: 20px;
                margin-top: -7px;
            }
            
            .circle {
                width: 22px;
                height: 22px;
                border: 1px solid #3279C6;
                border-radius: 12px;
                display: block;
                text-align: center;
                margin: auto;
                background: white;
            }
            
            .line {
                width: 1px;
                height: 120%;
                background: #3279C6;
                display: block;
                text-align: center;
                margin: auto;
            }
            
            ul {
                padding-left: 17px;
            }
            
            ul, li {
                font-size: 14px;
                line-height: 20px;
                color: #333333;
            }
            
            li {
                margin-bottom: 16px;
            }
        }
    }
    
    .box_wrapper {
        border: 1px solid #DEDEDE;
        padding: 3rem 2rem;
        border-radius: 8px;
        overflow: hidden;
        
        .date {
            font-size: 14px;
            color: #3279C6;
        }
        
        .divider {
            height: 1px;
            width: 100%;
            background: #DEDEDE;
            margin-bottom: 2rem;
            display: block;
        }
    }
    
    .education_item {
        display: inline-block;
        border: 1px solid #3279C6;
        padding: 6px 24px;
        font-size: 14px;
        color: #3279C6;
        margin-right: 16px;
        margin-bottom: 16px;
        border-radius: 20px;
    }
    
    .kids_banner {
        background: $primary;
        padding: 4rem!important;
        border-radius: 8px;
        
        .btn_turquiose {
            
            font-size: 16px;
            font-weight: bold;
            background: #00E7CF;
            height: 40px;
            text-align: center;
            padding: 8px 46px 0px 46px;
            display: inline-block;
            color: $primary;
            text-decoration: none;
            border-radius: 20px;
            
        }
    }
    
    .kids_banner_blue3 {
        background: $blue3;
        padding: 4rem!important;
        border-radius: 8px;
        
        .btn_turquiose {
            
            font-size: 16px;
            font-weight: bold;
            background: #00E7CF;
            height: 40px;
            text-align: center;
            padding: 8px 46px 0px 46px;
            display: inline-block;
            color: $primary;
            text-decoration: none;
            border-radius: 20px;
            
        }
    }
    
    .changing {
        background: #4A516B;
        padding-top: 5rem;
        padding-bottom: 5rem;
        margin-top: 4rem;
        
        position: relative;
        overflow: hidden;
        
        .btn_blue3 {
            
            font-size: 16px;
            font-weight: bold;
            background: $blue3;
            height: 40px;
            text-align: center;
            padding: 6px 46px;
            display: inline-block;
            color: white;
            text-decoration: none;
            border-radius: 20px;
            
        }
        
        .map_image {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .bell {
        display: none!important;
    }

    .flatpickr-calendar.open {
        display: inline-block;
        z-index: 9999999999 !important;
    }

    .date_select_full {
        position: relative;
    }

    .date_icon {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }

    .date-icon-start{
        position: absolute;
        right: 32px;
        bottom: 20px;
    }

    .fullwidth_dateselect {
        width: 100%;
        max-width: 100%;
    }
    
    /* The sticky class is added to the header with JS when it reaches its scroll position */
    .sticky {
        position: fixed;
        top: 60px;
        width: 100%;
        z-index: 99;

        .bell {
            display: inline-block!important;
        }
    }
    
    /* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
    .sticky + .targetcontent {
        padding-top: 102px;
    }
    
    #header_wrap_dp {
        background: transparent;
        position: relative!important;
        width: 100%;
        z-index: 99;
        top: 0;
        transition: top 0.2s ease-in-out;
    }
    
    .small_profile {
        border-radius: 50%;
        width: 75px !important;
        height: 75px !important;
        margin-top: 10px !important;
    }

    .small_profile_avatar {
        border-radius: 50%;
        width: 75px !important;
        height: 75px !important;
        line-height: 75px !important;
        font-size: 25px !important;
        margin-top: 10px !important;
    }
    
    .mefirst {
        z-index: 98;
        position: relative;
    }
    
    .nowyou {
        z-index: 88;
    }
    
    .sticky {
        h3 {
            font-size: 22px;
        }
        .dp_quali_items .item {
            color: #333;
            font-size: 11px;
            padding: 2px 6px!important;
            border-radius: 4px;
            background: #E1E4EC;
            display: inline-block;
            margin-right: 8px;
        }
        
        .dp_quali_items {
            margin-top: -16px;
        }
        
        .rating_item {
            opacity: 0;
        }
        
        .btn_book {
            font-size: 14px;
            padding: 9px 26px;
        }
        
        .action_icon_btn:hover span {
            left: 67px;
            opacity: 1;
        }
        
        .action_icon_btn {
            width: 85px;
        }
        
        .action_icon_btn:hover {
            background: #3279C6;
            width: 175px;
            padding-left: 30px;
        }
        
        .btn_booked {
            font-size: 14px;
            padding: 9px 26px;
            background: #00E7CF;
        }

        .views {
            font-size: 11px;
            color: white;
            font-weight: bold;
            margin-top: 5px!important;
        }
        
        .targethis {
            background: #131935;
            border-radius: 8px;
            padding: 30px;
            padding-top: 20px;
            padding-bottom: 0;
        }
        
        .name_wrapper {
            margin-left: 100px;
        }
    }
    
    #bookModal, #referalModal, #rejectProfile, #shareProfile {
       
        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 4rem;
        }
    }
    
    .btn_bluegrey {
        
        font-size: 15px;
        font-weight: bold;
        background: #4A516B;
        height: 40px;
        text-align: center;
        padding: 10px 46px;
        display: inline-block;
        color: white;
        text-decoration: none;
        border-radius: 20px;
        transition: 0.4s;
        
        &:hover {
            color: white;
            background: #131935;
        }
        
    }

    // .btn_blue {
        
    //     font-size: 15px;
    //     font-weight: bold;
    //     background: #3279C6;
    //     height: 40px;
    //     text-align: center;
    //     padding: 10px 46px;
    //     display: inline-block;
    //     color: white;
    //     text-decoration: none;
    //     border-radius: 20px;
    //     transition: 0.4s;
    //     outline: none;
    //     border-width: 0;
        
    //     &:hover {
    //         color: white;
    //         background: #23558a;
    //     }
        
    // }
    
    .modal .close {
        border: none;
        background: transparent;
        position: absolute;
        right: 20px;
        top: 4px;
        z-index: 99999;
        font-size: 40px;
    }
    
    .blue3_banner_image {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
    }
    
    .adjustheight {
        margin-top: -30px;
    }
    
    .embed-responsive.embed-responsive-16by9.mb-5 {
        margin-top: 46px;
    }

    .refer_form_wrapper {
        border: 1px solid #D6D5D5;
        padding: 3rem;
        border-radius: 8px;
    }
    
    
    // Responsive
    
    @media (max-width: 992px) {
        .digital_profiles_wrapper {
            .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .dp_header .profile_image {
            margin-top: -50px;
        }

        .sticky {
            .profile_wrapper {
                position: relative;
                display: inline-block;
                width: 100%;
                max-width: 70px!important;
            }
        }
        
        #dp_experience .work_wrapper .circle,
        #dp_experience .work_wrapper .line {
            display: none;
        }
        .blue3_banner_image {
            display: none!important;
        }
        
        #dp_skills {
            margin-top: 0rem;
        }
        
        .dp_header .rating_item {
            display: none;
        }
        
        .dp_header .action_icon_btn {
            width: 85px;
            font-size: 14px;
            padding: 9px 26px;
        }

        .dp_header .btn_booked {
            width: 85px;
            font-size: 14px;
            padding: 9px 26px;
        }

        .dp_header .container{
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            margin-left: 0;
            margin: 0;
            max-width: 100%;

            h3 {
                font-size: 22px;
            }
        }
        
        .dp_header .profile_wrapper {
            position: relative;
            display: inline-block;
            width: 100%;
            max-width: 140px;
        }
        
        .dp_header .name_wrapper {
            margin-left: 30px;
            display: inline-block;
            padding-top: 20px;
        }

        .dp_header .row {
            background: #131935;
            border-radius: 8px;
            padding: 30px;
            padding-bottom: 20px;
        }
    }

    @media (max-width: 667px) {

        .modal .close {
            border: none;
            background: transparent;
            position: absolute;
            right: 20px;
            top: 4px;
            z-index: 99999;
            font-size: 30px;
        }

        #referalModal .modal-dialog,
        #bookModal .modal-dialog {
            max-width: 90vw;
            margin-right: auto;
            margin-left: auto;
        }
        #referalModal .modal-body,
        #bookModal .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 2rem;
        }

        #dp_skills .skill_block {
            border: 1px solid #DEDEDE;
            border-radius: 8px;
            padding: 20px;
        }

        .digital_profiles_wrapper {
            .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        #dp_skills .industry_exp {
            background: #F3F4F7;
            border-radius: 8px;
            padding: 20px;
        }

        .box_wrapper {
            border: 1px solid #DEDEDE;
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;
        }

        .digital_profiles_wrapper {
            #stickyHeader {
                .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .kids_banner {
            background: #131935;
            padding: 2rem !important;
        }

        .kids_banner_blue3 {
            background: #216AAA;
            padding: 20px;
            border-radius: 8px;
        }

        .dp_pre_header .notice_orange {
            background: #F28F16;
            color: white;
            padding: 8px 24px;
            border-radius: 8px;
            margin-left: 0px;
        }

        .dp_pre_header {
            padding-left: 30px;
            padding-right: 30px;
        }

        .mobileHeader {
            background: #131935;
                border-radius: 8px;
                padding: 10px!important;
                padding-bottom: 10px!important;

            .profile_wrapper {
                position: relative;
                display: inline-block;
                width: 100%;
                max-width: 70px;
                margin-top: 0;
            }
            
            .action_icon, .neg {
                width: 30px;
                height: 30px;
                padding-left: 6px;
                padding-top: 0px;
                margin-right: 1px;
            }

            .profile_image_mobile {
                max-width: 60px;
            }

            .profile-avatar_mobile {
                width: 60px;
                height: 60px;
                text-transform: uppercase;
                text-align: center;
                font-weight: bold;
                line-height: 60px;
                background: #90b7e0;
                color: #fff;
                font-size: 25px;
                transition: 1s;
                border-radius: 50%;
            }

            h3 {
                font-size: 18px!important;
                line-height: 20px!important;
            }

        }
    }

    .logo_dp {
        max-width: 100px;
    }
    
    
    .about_modal {
        color: #797979;
        
        &:hover {
            color: #535252;
        }
    }