.iti {
	display: block;
}

.iti__selected-flag {
	z-index: 1;
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 6px 0 8px;
	border-right: 1px solid #D6D5D5;
}

.iti--separate-dial-code .iti__selected-flag {
	background-color: white;
}


// Generic Styles

label {
	color: #333333;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 8px;
}

.form-control {
	display: block;
	width: 100%;
	height: 45px;
	padding: 0.375rem 0.75rem;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #333;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #D6D5D5;
	border-radius: 4px;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-group, .form-row {
	margin-bottom: 28px;
}

.form-group {
	margin-bottom: 28px;
	margin-top: 0!important;
}

#message {
	height: 200px;
}

.intl-tel-input.separate-dial-code .selected-flag {
	background-color: transparent;
	display: table;
}

.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
	width: 78px;
	border-right: 1px solid #d6d5d5!important;
}

.contact_form_wrapper_white {
	background: white;
		border-radius: 16px;
		padding: 4rem;
		z-index: 99;
		position: relative;

		@media (max-width:768px) {
			padding: 2rem;
			text-align: left;
		}
}