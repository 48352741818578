.handout {
	padding-top: 14rem;
}

.why_edu {
	.row {
		min-height: 70vh;
    height: 100%;
	}

	img.impact_image {
		position: absolute;
		right: -50px;
		bottom: -22%;
		z-index: 99;
	}
}


.hwp {
	padding-top: 13rem;
}

.impact_block {
	border-radius: 8px;
    position: relative;
	padding: 2rem 2rem 0rem 2rem;

	p {
		min-height: 75px;
	}
}


// Impact Updated

#impact {

	h4.sm.text-blue2.text-uppercase.letters_spaced {
		@media (max-width:992px) {
			font-size: 14px;
			line-height: 23px;
			letter-spacing: 5px;
		}
	}


	h2.xl {
		font-size: 54px;
		line-height: 65px;
		@media (max-width:992px) {
			font-size: 40px;
			line-height: 46px;
		}
	}
}

.impact_header {
	padding-top: 17rem;
	padding-bottom: 10rem;
}

.impact_section_two {

	position: relative;

	padding-top: 10rem;
	padding-bottom: 10rem;

	h4 {
		font-size: 20px;
		letter-spacing: 8px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.impact_accent_image {
		position: absolute;
		right: 0;
		top: -5rem;
		max-width: 45vw;
	}
}

.video_section {
	padding-top: 10rem;
	padding-bottom: 15rem;
}

.py10 {
	padding-top: 10rem;
	padding-bottom: 10rem;
}

.books_graphic {
	position: absolute;
	top: 10rem;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 999;
}

.add_thingy_magik {
	&:before {
		content: '';
		margin-right: 20px;
		display: inline-block;
		width: 2px;
		height: 22px;
		background-color: $turquoise;
		margin-bottom: -7px;
	}
}



.report_download {
	background: white;
	border-radius: 8px;
	padding: 3rem;

	.report_date {
		font-size: 14px;
		font-weight: bold;
		color: #2B6AAD;
		letter-spacing: 4px;
	}

	h3 {
		font-size: 30px;
		margin-top: -15px!important;
		font-weight: 500!important;
	}
}